import {
  Box, Button, InputBase, Link, Paper, Typography,
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import ROUTES from '../../../constants/routes';
import dashboardBackground from '../../../assets/dashboardBackground.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DoubleArrowIcon from '../../../assets/doubleArrows.png'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import StocksSearch from '../../../components/StockSearch/StocksSearch';
import TopPerformersWidget from './TopPerformersWidget';
import { loginRequest } from '../../../authConfig';

export default function DashboardTop() {
  const { instance } = useMsal();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState<string>('');
  const [searchMenuIsOpen, setSearchMenuIsOpen] = useState<boolean>(false);

  function handleChangeTextFieldValue(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchValue(() => e.target.value);
    setSearchMenuIsOpen(() => true);
  }

  function handleClickMenuItem(companySymbol: string) {
    if (companySymbol === '') return;
    navigate(ROUTES.stockDetailedData(companySymbol));
  }

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100vh',
        backgroundImage: `url(${dashboardBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative'
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          bottom: '30px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 1
        }}
      >
        <img src={DoubleArrowIcon} alt="scroll indicator" />
      </Box>
      <AuthenticatedTemplate>
        <Box sx={{ paddingTop: '8%', marginLeft: '5%', paddingRight: { lg: '50%' } }}>
          <Box
            sx={{
              display: 'flex',
              zIndex: '1',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              marginTop: '50px',
            }}
          >
            <Typography
              className="dashboard-typography-heaer"
              sx={{
                color: '#30CFD0', fontSize: '48px', fontFamily: 'Calibre', fontWeight: '600', textShadow: '2px 4px 9.6px #00000017'
              }}
            >
              Look for your stock
            </Typography>
            <Typography
              sx={{
                color: 'black', fontSize: '14', fontFamily: 'Calibre'
              }}
            >
              Enter symbol or company name
            </Typography>
            <Box sx={{ alignSelf: 'left' }}>
              <StocksSearch
                inputValue={searchValue}
                onClickMenuItem={handleClickMenuItem}
                searchMenuIsOpen={searchMenuIsOpen}
                setSearchMenuIsOpen={setSearchMenuIsOpen}
              >
                <Paper
                  style={{
                    borderRadius: '15px',
                    boxShadow: '2px 8px 12.4px #00000008', // Optional: add a shadow for better visibility,
                    height: '59px',
                    width: '376px',
                    border: '1px solid #0000001A'
                  }}

                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '5px 10px 5px 0',
                    position: 'relative',
                    alignItems: 'center',
                    backgroundColor: '#F9F9F912'
                  }}
                >
                  <InputBase
                    value={searchValue}
                    placeholder="Search..."
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeTextFieldValue(e)}
                    sx={{ width: '400px', marginLeft: '20px', color: 'black' }}
                  />
                </Paper>
              </StocksSearch>
            </Box>
            <Typography
              className="dashboard-typography-heaer"
              sx={{
                color: 'black', fontSize: '24px', fontFamily: 'Calibre', fontWeight: '600', textShadow: '2px 4px 9.6px #00000017', marginTop: '5%', marginBottom: '4%'
              }}
            >
              OR
            </Typography>
            <Typography
              className="dashboard-typography-heaer"
              sx={{
                color: '#30CFD0', fontSize: '48px', fontFamily: 'Calibre', fontWeight: '600', textShadow: '2px 4px 9.6px #00000017'
              }}
            >
              Search for investing opportunities
            </Typography>
            <Box sx={{ alignSelf: 'left' }}>
              <Paper elevation={4} sx={{ width: '376px', height: '52px' }}>
                <Button variant="contained" aria-label="Stock screener"
                  sx={{ width: '100%', height: '100%', fontWeight: '600', fontSize: '17px', backgroundColor: '#30CFD0', textTransform: 'none' }}
                  onClick={() => navigate(ROUTES.stockScreener.dividend)}
                >
                  Go to search engine
                </Button>
              </Paper>
            </Box>
          </Box>
        </Box>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Box sx={{ paddingTop: '12%', marginLeft: '5%', paddingRight: { lg: '50%' } }}>
          <Typography sx={{ fontWeight: "400", fontSize: '52px', fontFamily: 'Calibre', color: 'black', lineHeight: '70px' }}>
            Decrease investment risk by making <span style={{ fontWeight: '600' }}>intelligent decisions</span>
          </Typography>
          <Typography sx={{ fontWeight: "400", fontSize: '24px', fontFamily: 'Calibre', color: 'black', paddingRight: '40%', lineHeight: '30px' }}>
            We are the only investment tool you need to make <span style={{ fontWeight: '600' }}>informed</span> decisions <span style={{ fontWeight: '600' }}>faster</span>
          </Typography>
          <Paper elevation={4} sx={{ width: '343px', height: '52px', marginTop: '5%' }}>
            <Button variant="contained" endIcon={<ArrowForwardIcon />} aria-label="Try it for free"
              sx={{ width: '100%', height: '100%', fontWeight: '600', fontSize: '17px', backgroundColor: '#30CFD0', textTransform: 'none' }}
              onClick={() => instance.loginRedirect(loginRequest)}
            >
              Register and Try for Free
            </Button>
          </Paper>
        </Box>
      </UnauthenticatedTemplate>
    </Box>
  );
}
