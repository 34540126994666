import { AccountCircle, Gavel } from '@mui/icons-material';
import {
  Box,
  IconButton, InputBase, Menu, MenuItem, Paper, Theme, Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTheme } from '@mui/styles';
import { useAccount, useMsal } from '@azure/msal-react';
import { useColorModeContext } from '../../contexts/ColorModeContext';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES from '../../constants/routes';
import DocumentsMenu from './DocumentsMenu';
import SubscriptionService from '../../services/subscriptionService';
import StocksSearch from '../../components/StockSearch/StocksSearch';

export default function AuthorizedUserMenu() {
  const navigate = useNavigate();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});


  const [anchorUserEl, setAnchorUserEl] = useState<null | HTMLElement>(null);
  const [anchorDocEl, setAnchorDocEl] = useState<null | HTMLElement>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchMenuIsOpen, setSearchMenuIsOpen] = useState<boolean>(false);

  const location = useLocation();
  const showHeaderSearch = location.pathname !== '/' && !location.pathname.includes('stockScreener/screener');


  const { instance } = useMsal();
  const theme: Theme = useTheme();
  const colorMode = useColorModeContext();

  const handleDocMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorDocEl(event.currentTarget);
  };

  const handleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorUserEl(event.currentTarget);
  };

  const handleUserClose = () => {
    setAnchorUserEl(null);
  };

  const handleDocClose = () => {
    setAnchorDocEl(null);
  };

  const handleSignOut = () => {
    instance.logoutRedirect({ postLogoutRedirectUri: '/' });
    setAnchorUserEl(null);
  };

  function handleClickMenuItem(companySymbol: string) {
    if (companySymbol === '') return;
    navigate(ROUTES.stockDetailedData(companySymbol));
    setSearchMenuIsOpen(() => false);
  }

  function handleChangeTextFieldValue(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchValue(() => e.target.value);
    setSearchMenuIsOpen(() => true);
  }

  const handleManageSubscription = async () => {
    const email: string = account?.idTokenClaims?.email as string;

    if (email) {
      const result = await SubscriptionService.getSubscriptionManagementUrl();

      if (result && typeof result.data === 'string') {
        const url = result.data;
        const isAbsoluteUrl = /^https?:\/\//i.test(url); // Check if URL is absolute

        if (isAbsoluteUrl) {
          window.open(url, '_blank'); // Open absolute URL in a new tab
        } else {
          navigate(url); // Use in-app navigation for relative URLs
        }
      }
    }

    setAnchorUserEl(null);
  };

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ float: 'left' }}>
        {showHeaderSearch && <Box sx={{ alignSelf: 'left' }}>
          <StocksSearch
            inputValue={searchValue}
            onClickMenuItem={handleClickMenuItem}
            searchMenuIsOpen={searchMenuIsOpen}
            setSearchMenuIsOpen={setSearchMenuIsOpen}
          >
            <Paper
              style={{
                borderRadius: '15px',
                boxShadow: '2px 8px 12.4px #00000008', // Optional: add a shadow for better visibility,
                height: '36px',
                width: '240px',
                border: '1px solid #0000001A',
                marginTop: '2px'
              }}

              sx={{
                display: 'flex',
                justifyContent: 'center',
                padding: '5px 10px 5px 0',
                position: 'relative',
                alignItems: 'center',
                backgroundColor: '#F9F9F912'
              }}
            >
              <InputBase
                value={searchValue}
                placeholder="Search for your stock..."
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeTextFieldValue(e)}
                sx={{ width: '400px', marginLeft: '20px', color: 'black' }}
              />
            </Paper>
          </StocksSearch>
        </Box>}
      </div>
      <div style={{ float: 'left' }}>
        <IconButton
          size="large"
          aria-label="pricing"
          onClick={() => { navigate('pricing') }}
          color="inherit"
          style={{ float: 'left', borderRadius: '0px' }}
        >
          <Typography sx={{ overflow: 'hidden', fontWeight: '600' }} variant="body2">
            Pricing
          </Typography>
        </IconButton>
      </div>
      <div style={{ float: 'left' }}>
        <DocumentsMenu />
      </div>
      <div style={{ float: 'left' }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleUserMenu}
          color="inherit"
          style={{ float: 'left', borderRadius: '0px' }}
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorUserEl}
          style={{ zIndex: theme.zIndex.tooltip, float: 'left' }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorUserEl)}
          onClose={handleUserClose}
        >
          {/* <MenuItem onClick={handleChangeColorTheme}>Change theme</MenuItem> */}
          <MenuItem onClick={handleManageSubscription}>Your subscriptions</MenuItem>
          <MenuItem sx={{ minWidth: '300px' }} onClick={handleSignOut}>Sign out</MenuItem>
        </Menu>
      </div>
    </div>
  );
}
