import React from 'react';
import { Box, Grid, Paper, Stack, styled, Switch, Typography } from "@mui/material";
import FreeResourcesExceededDialogSubscription from "../../../layout/components/FreeResourceExceededDialogSubscription";
import { getSubscriptionOldPrice, getSubscriptionPrice, subscriptionPlans, SubscriptionPlanType } from "../../../models/subscriptionConfig";
import { useSubscriptionCheckout } from "../../../hooks/useSubscriptionCheckout";
import DoubleArrowsIcon from '../../../assets/doubleArrows.png';

export default function ChooseYourPlan() {
    const [isAnnual, setIsAnnual] = React.useState(false);
    const { buySubscription } = useSubscriptionCheckout();

    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: '100%',
        width: '450px',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        border: '1px solid #7E57C24D'
    }));

    const handleBuySubscription = (planType: SubscriptionPlanType, isTrial: boolean) => {
        buySubscription(planType, isAnnual, true);
    };

    const handleIsAnnualChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsAnnual(event.target.checked);
    };

    return (
        <Box
            sx={{
                width: '100%',
                minHeight: '110vh',
                position: 'relative',
                overflow: 'hidden',
                background: `url("data:image/svg+xml,${encodeURIComponent(`
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 600">
                        <rect width="100%" height="100%" fill="white"/>
                        <defs>
                            <radialGradient id="circleGradient" cx="50%" cy="50%" r="50%">
                                <stop offset="0%" style="stop-color:#33086760"/>
                                <stop offset="100%" style="stop-color:#33086700"/>
                            </radialGradient>
                        </defs>
                        <circle cx="0" cy="300" r="160" fill="url(#circleGradient)"/>
                        <circle cx="250" cy="300" r="160" fill="url(#circleGradient)"/>
                        <circle cx="500" cy="300" r="160" fill="url(#circleGradient)"/>
                        <circle cx="700" cy="420" r="160" fill="url(#circleGradient)"/>
                        <circle cx="950" cy="410" r="160" fill="url(#circleGradient)"/>
                    </svg>
                `)}")`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    bottom: '30px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 1000
                }}
            >
                <img
                    src={DoubleArrowsIcon}
                    alt="scroll indicator"
                    style={{ width: '35px', height: '35px' }}
                />
            </Box>
            <Grid container sx={{
                display: { xs: 'block', md: 'flex' },
                justifyContent: { md: 'center' },
                alignItems: { md: 'center' },
                marginTop: '50px',
                gap: { md: 8 }
            }}>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Typography
                        sx={{
                            fontSize: '62px',
                            fontFamily: 'Calibre, sans-serif',
                            fontWeight: '600',
                            marginTop: '3%'
                        }}
                    >
                        Choose your plan
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <Stack direction="row" component="label" alignItems="center" justifyContent="center">
                            <Typography style={{ fontWeight: '600' }}>Monthly</Typography>
                            <Switch color='secondary' checked={isAnnual} onChange={handleIsAnnualChange} />
                            <Typography style={{ fontWeight: '600', marginRight: '4px' }}>Annually:</Typography>
                            <Typography style={{ marginRight: '4px' }}>Additional</Typography>
                            <Typography style={{ fontWeight: '600', marginRight: '4px' }}>20%</Typography>
                            <Typography>discount</Typography>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12}
                    md="auto"
                    sx={{
                        display: { xs: 'block', md: 'flex' },
                        alignItems: { md: 'center' },
                        mb: { xs: 2, md: 0 },
                        height: '60vh',
                        '& > *': {
                            height: '100%'
                        }
                    }}>
                    <Item>
                        <FreeResourcesExceededDialogSubscription
                            isAnnual={isAnnual}
                            buySubscription={(isTrial) => handleBuySubscription(SubscriptionPlanType.PREMIUM, isTrial)}
                            subscriptionName={subscriptionPlans[SubscriptionPlanType.PREMIUM].displayName}
                            price={getSubscriptionPrice(SubscriptionPlanType.PREMIUM, isAnnual, false)}
                            oldPrice={getSubscriptionOldPrice(SubscriptionPlanType.PREMIUM, isAnnual, false)}
                            isPrefered={false}
                            subscriptionResources={subscriptionPlans[SubscriptionPlanType.PREMIUM].resources}
                        />
                    </Item>
                </Grid>
                <Grid item xs={12}
                    md="auto"
                    sx={{
                        display: { xs: 'block', md: 'flex' },
                        alignItems: { md: 'center' },
                        mb: { xs: 2, md: 0 },
                        height: '60vh',
                        '& > *': {
                            height: '100%'
                        }
                    }}>
                    <Item>
                        <FreeResourcesExceededDialogSubscription
                            isAnnual={isAnnual}
                            buySubscription={(isTrial) => handleBuySubscription(SubscriptionPlanType.DIAMOND, isTrial)}
                            subscriptionName={subscriptionPlans[SubscriptionPlanType.DIAMOND].displayName}
                            price={getSubscriptionPrice(SubscriptionPlanType.DIAMOND, isAnnual, false)}
                            oldPrice={getSubscriptionOldPrice(SubscriptionPlanType.DIAMOND, isAnnual, false)}
                            isPrefered
                            subscriptionResources={subscriptionPlans[SubscriptionPlanType.DIAMOND].resources}
                        />
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
}