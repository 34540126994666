import {
  Box, Button, Dialog, Divider, Grid, Paper, Tooltip, Typography, styled,
} from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';

interface FreeResourcesExceededDialogSubscriptionPropsModel {
  buySubscription: (isTrial: boolean) => void,
  subscriptionName: string,
  price: number,
  oldPrice: number,
  isPrefered: boolean,
  subscriptionResources: string[],
  isAnnual: boolean
}

interface SubscriptionResourcePropsModel {
  label: string
}

function SubscriptionResource({ label }: SubscriptionResourcePropsModel) {
  return (
    <Box sx={{ position: 'relative', paddingLeft: '22px' }}>
      <FiberManualRecordIcon style={{ position: 'absolute', left: '0px', top: '3px', height: '10px', color: '#A7A7A7' }} />
      <Typography style={{
        fontSize: '12px', fontWeight: '600', marginBottom: '3%', marginTop: '3%', color: '#424242'
      }}
      >
        {label}
      </Typography>
    </Box>
  );
}

export default function FreeResourcesExceededDialogSubscription(
  {
    buySubscription, subscriptionName, price, oldPrice, isPrefered, subscriptionResources, isAnnual
  }: FreeResourcesExceededDialogSubscriptionPropsModel,
) {
  const { instance, accounts } = useMsal();

  return (
    <>
      <Box sx={{
        height: '5%', marginTop: '10px', color: '#FFAB00', fontWeight: '700', marginBlock: '0 0 1rem', fontSize: '1.5rem',
      }}
      >
        {isPrefered && <RocketLaunchIcon />}
      </Box>
      <Box style={{
        height: '6%', marginBottom: '20px', color: isPrefered ? '#FFAB00' : '#0288D1', fontWeight: '700', marginBlock: '0 0 1rem', fontSize: '1.5rem', textTransform: 'uppercase'
      }}
      >
        {subscriptionName}
      </Box>
      <Box sx={{
        height: '42%', margin: '20px 0 40px 0', padding: '0 5% 0 5%', fontWeight: '400', fontSize: '0.75rem', textAlign: 'left',
      }}
      >
        {subscriptionResources.map((r, i, { length }) => (
          <Box>
            <SubscriptionResource label={r} />
            {i + 1 !== length && <Divider sx={{ marginLeft: '10px' }} />}
          </Box>
        ))}
      </Box>
      <Box sx={{
        height: '2%', fontWeight: '750', fontSize: '8px',
      }}
      >
        LIMITED PRICE
        <Tooltip
          title="This special price is available for a limited time only"
          arrow
          placement="top"
        >
          <InfoOutlinedIcon sx={{
            fontSize: '12px',
            color: '#FFAB00',
            cursor: 'pointer'
          }} />
        </Tooltip>
        :
      </Box>
      <Box sx={{
        height: '8%', margin: '10px', fontWeight: '600', fontSize: '12px',
      }}
      >
        <s style={{ fontWeight: '800' }}>
          {oldPrice}
          $
        </s>
        {' '}
        <DoubleArrowIcon style={{ fontSize: '12px', color: '#FFB633' }} />
        {' '}
        <a style={{ fontWeight: '800' }}>
          {price}
          $
          {' '}
        </a>
        <a style={{ fontWeight: '600', fontSize: '11px' }}>
          /{isAnnual ? 'year' : 'month'}
        </a>
      </Box>
      <AuthenticatedTemplate>
        <Box sx={{
          height: '10%', fontWeight: '400', borderRadius: '8px', fontSize: '1.5rem',
        }}
        >
          {isPrefered
            ? (
              <Button variant="contained" color="success" sx={{ width: '70%', fontSize: '10px', backgroundColor: '#7E57C2', fontWeight: '600' }} onClick={() => buySubscription(true)}>
                I choose
                {' '}
                {subscriptionName}
              </Button>
            )
            : (
              <Button
                variant="outlined"
                color="secondary"
                sx={{
                  width: '70%', fontSize: '10px', borderColor: '#0288D180', color: '#0288D180', fontWeight: '600'
                }}
                onClick={() => buySubscription(false)}
              >
                I choose
                {' '}
                {subscriptionName}
              </Button>
            )}
          <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>Free trial</Typography>
          {/* <Button style={{ fontSize: '12px', color: '#0094FF' }} onClick={() => buySubscription(true)}>Try 7 days for free!</Button> */}
        </Box>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Button variant="contained" color="success" sx={{ width: '70%', fontSize: '10px', backgroundColor: '#7E57C2', fontWeight: '600', margin: 'auto' }} onClick={() => instance.loginRedirect(loginRequest)}>
          Register for free
        </Button>
      </UnauthenticatedTemplate>
    </>
  );
}
