import { makeStyles } from '@mui/styles';

const useDrawerStyles = makeStyles((theme: any) => ({
  drawerPaper: {
    backgroundColor: '#FFFFFF',
    boxShadow: '4px 0 8px -3px rgba(0, 0, 0, 0.2)',
    position: 'static',
  },
}));

export default useDrawerStyles;
