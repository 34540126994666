import { Box, Paper, Typography } from '@mui/material';
import BasicTable from '../../../../../components/BasicTable/BasicTable';
import useCompanyOverview from '../../../../hooks/useCompanyOverview';

interface DividendTablePropsType{
  stockSymbol : string | undefined,
  width : string
  paperHeight : string
}

export default function DividendTable({ stockSymbol, width, paperHeight }: DividendTablePropsType) {
  const {
    data: companyOverview,
    isLoading: isLoadingCompanyOverview,
  } = useCompanyOverview(stockSymbol as string);

  return (
    <Box sx={{ width, maxWidth: '100%' }}>
      <Typography variant="h6" sx={{ padding: '5px 0px 5px 15px', width: '100%', textAlign: 'left', fontSize: '18px', fontWeight: '700' }}>
        DIVIDEND
      </Typography>
      <Paper sx={{ width, maxWidth: '100%', height: paperHeight }} elevation={7}>
        <BasicTable
          data={{
            'Dividend yield': `${companyOverview?.dividendYieldTTM.toPrecision(3)}%`,
            'Payout ratio': `${companyOverview?.payoutRatioTTM.toPrecision(3)}%`,
            'Years of div. Increase': companyOverview?.consecutiveYearsOfDividendIncrease,
            'Years of div.': companyOverview?.consecutiveYearsOfDividend,
            'Q-shift': companyOverview?.qShift,
            'Dividend per share': companyOverview?.dividendPerShareTTM,
          }}
          isLoading={isLoadingCompanyOverview}
        />
      </Paper>
    </Box>
  );
}
