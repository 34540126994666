import { Box, Button, Grid, Paper, Typography, styled } from '@mui/material';
import FreeResourcesExceededDialogSubscription from '../../layout/components/FreeResourceExceededDialogSubscription';
import ComparePlans from '../dashboard/components/ComparePlans';
import ForWho from '../dashboard/components/ForWho';
import { loginRequest } from '../../authConfig';
import { UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import FAQ from '../dashboard/components/FAQ';
import ChooseYourPlan from './components/ChooseYourPlan';

export default function Pricing() {

    const { instance } = useMsal();


    return (
        <Box style={{ width: '100%' }}>
            <ChooseYourPlan />
            <Box>
                <ComparePlans />
            </Box>
            <Box style={{ paddingTop: '70px' }}>
                <ForWho />
            </Box>
            <UnauthenticatedTemplate>
                <Box
                    sx={{
                        width: '100%',
                        background: 'white',
                        display: 'flex',
                        flexDirection: 'column', // Stack button and typography row vertically
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingBottom: '100px'
                    }}
                >
                    <Button
                        style={{
                            padding: '12px 50px',
                            textTransform: 'none',
                            borderRadius: '50px',
                            background: 'linear-gradient(90deg, #330867 0%, #30CFD0 100%)',
                            color: 'white',
                            fontSize: '20px'
                        }}
                        variant="contained"
                        size="large"
                        onClick={() => instance.loginRedirect(loginRequest)}
                        aria-label="I'm starting for free button"
                    >
                        I'm starting for free
                    </Button>
                </Box>
            </UnauthenticatedTemplate>
            <FAQ />
            <UnauthenticatedTemplate>
                <Box
                    sx={{
                        width: '100%',
                        background: 'white',
                        display: 'flex',
                        flexDirection: 'column', // Stack button and typography row vertically
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: '50px',
                        paddingBottom: '150px'
                    }}
                >
                    <Button
                        style={{
                            padding: '12px 50px',
                            margin: '10px',
                            textTransform: 'none',
                            borderRadius: '10px',  // Rounded edges
                            background: '#30CFD0',  // Gradient background
                            color: 'white',
                            fontSize: '20px',
                            boxShadow: '2px 8px 12.4px 1px #00000021'
                        }}
                        variant="contained"
                        size="large"
                        onClick={() => instance.loginRedirect(loginRequest)}
                        aria-label="I'm starting for free button"
                    >
                        I'm starting for free
                    </Button>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '16px', // Space between typographies
                            marginTop: '8px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '13px',
                            }}
                        >
                            No card needed
                        </Typography>
                    </Box>
                </Box>
            </UnauthenticatedTemplate>
        </Box>
    );
}
