import axios from 'axios';

const config = { headers: { 'Content-Type': 'application/json' } };

const getSubscriptionManagementUrl = async () => {
    const URL = `${process.env.REACT_APP_SERVER_URL}/Subscription/GetSubscriptionManagementUrl`;
    const result = await axios.get(URL, config);
    return result;
};

const getSubscriptionLevel = async () => {
    const URL = `${process.env.REACT_APP_SERVER_URL}/Subscription/Level`;
    const result = await axios.get(URL, config);
    return result;
};

const handleNewOrderCreated = async (orderId: string) => {
    const URL = `${process.env.REACT_APP_SERVER_URL}/Subscription/HandleNewOrderCreated/${orderId}`;
    await axios.post(URL, config);
};

const SubscriptionService = {
    getSubscriptionManagementUrl,
    handleNewOrderCreated,
    getSubscriptionLevel
};

export default SubscriptionService;
