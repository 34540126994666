import {
  Box,
  Grid,
  Paper,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { useState } from 'react';
import humanize from '../services/humanize';

interface PricePositionPropsModel {
  price: number,
  currency: string,
  positionQuantity?: number
  change: number
}

export default function PricePosition(props: PricePositionPropsModel) {
  const { } = props;

  const Container = styled(Paper)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: '30px 0 30px 30px',
  });

  const Text = styled(Typography)({
    marginLeft: '5px',
    fontSize: '15px',
    fontWeight: '600'
  });

  const PercentageChange = styled(Typography)({
    fontSize: '0.7rem',
    fontWeight: '600',
    marginBottom: '10px',
    marginLeft: '3px'
  });

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
      <Container>
        <Grid container sx={{ width: '100%', padding: '10px' }}>
          <Grid item xs={12} lg={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <Typography sx={{ fontWeight: '600' }}>Price:</Typography>
              <Text sx={{ color: props.change >= 0 ? '#0EAF00' : 'red' }}>
                {humanize.compactNumber(props.price, 2)}&nbsp;{props.currency}
              </Text>
              <PercentageChange sx={{ color: props.change >= 0 ? '#0EAF00' : 'red' }}>
                (&nbsp;{props.change > 0 ? '+' : '-'}&nbsp;{humanize.compactNumber(props.change, 2)}&nbsp;{props.currency})
              </PercentageChange>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%'
            }}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 'auto'
              }}>
                <Typography sx={{ fontWeight: '600' }}>Your&nbsp;position: </Typography>
                <Text style={{ color: '#0EAF00' }}>{props.positionQuantity && humanize.compactNumber(props.positionQuantity * props.price, 2)}&nbsp;{props.currency}</Text>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
