import { makeStyles } from '@mui/styles';

const useMenuItemStyles = makeStyles((theme: any) => ({
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      borderRadius: '8px',
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.action.hover,
      borderRadius: '8px',
    },
    backgroundColor: 'inherit',
    color: theme.palette.common.white,
    borderRadius: '8px',
  },
}));

export default useMenuItemStyles;