/* eslint-disable react/no-unstable-nested-components */
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import './ActionsCell.css';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SnackbarKey, useSnackbar } from 'notistack';
import { useIsAuthenticated } from '@azure/msal-react';
import useIgnoredStocks from '../../hooks/useIgnoredStocks';
import StockService from '../../services/stockService';
import SnackbarUndoButton from '../Snackbar/SnackbarUndoButton';
import { useDialogContext } from '../../contexts/DialogContext';
import StockDetailedData from '../../models/stockDetailedData';
import usePortfolioValueByCompanyByPortfolioIds from '../../hooks/usePortfoliosValueByCompany';
import AddToPortfolioDialog from './Dialog/AddToPortfolioDialog/AddToPortfolioDialog';
import { useFreeResourcesExceededDialogContext } from '../../contexts/FreeResourcesExceededDialogContext';
import AddToWatchlistDialog from './Dialog/AddToWatchlistDialog/AddToWatchlistDialog';
import { VisibilityOutlined } from '@mui/icons-material';
import useWatchlistNames from '../../hooks/useWatchlistNames';
import usePortfolioNames from '../../hooks/usePortfolioNames';

interface ActionsCellProps {
  symbol: string
  stockData: StockDetailedData
}

export default function ActionsCell({ symbol, stockData }: ActionsCellProps) {
  const { data: ignoredStocks, dataUpdatedAt: ignoredDataUpdatedAt } = useIgnoredStocks();
  const { data: watchlists, dataUpdatedAt: watchlistsDataUpdatedAt } = useWatchlistNames();
  const { data: portfolioNames, dataUpdatedAt: portfolioDataUpdatedAt } = usePortfolioNames();
  const QueryClient = useQueryClient();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isInWatchlists, setIsInWatchlists] = useState<boolean | undefined>(undefined);
  const [isInIgnored, setIsInIgnored] = useState<boolean | undefined>(undefined);
  const [isInPortfolio, setIsInPortfolio] = useState<boolean | undefined>(undefined);
  const [isPortfolioModalOpen, setIsPortfolioModalOpen] = useState<boolean>(false);
  const [isWatchlistModalOpen, setIsWatchlistModalOpen] = useState<boolean>(false);
  const isAuthenticated = useIsAuthenticated();
  const notLoggedUserDialog = useDialogContext();
  const freeResourcesExceededDialogContext = useFreeResourcesExceededDialogContext();


  useEffect(() => {
    if (watchlists && watchlists.length > 0)
      setIsInWatchlists(watchlists.some((w: { symbols: string | string[]; }) => w.symbols.includes(symbol)));
  }, [watchlistsDataUpdatedAt]);

  useEffect(() => {
    setIsInIgnored(ignoredStocks?.includes(symbol));
  }, [ignoredDataUpdatedAt]);

  useEffect(() => {
    setIsInPortfolio(portfolioNames?.some((p: any) => p.symbolValues.some((s: any) => s.symbol == symbol)));
  }, [portfolioDataUpdatedAt]);

  function putNewIgnoredStockToCache() {
    setIsInIgnored(true);
    QueryClient.setQueryData(
      ['getIgnoredStocks'],
      (oldModel: any) => [...oldModel, symbol],
    );
    QueryClient.setQueryData(
      ['getStockDetailedData', 'Ignored'],
      (oldModel: any) => {
        if (oldModel) {
          return [...oldModel, stockData];
        }
        return oldModel;
      },
    );
  }

  const putNewIgnoredStock = useMutation((symbolToPut: string) => (
    StockService.putNewIgnoredStock(symbolToPut)), {
    onError: ((error: any) => {
      if (error?.response?.status == 403) {
        freeResourcesExceededDialogContext.setDialogText('IGNORED STOCKS');
        freeResourcesExceededDialogContext.setIsDialogOpened(true);
      }
      else {
        enqueueSnackbar('Adding to ignored failed, try again.', { variant: 'error' });
      }
    }),
    onSuccess: (data, variables) => {
      putNewIgnoredStockToCache();
      enqueueSnackbar(`${variables} stock succesfully added to ignored`, {
        action: (snackbarId: SnackbarKey) => (
          <SnackbarUndoButton undoClick={() => {
            // eslint-disable-next-line no-use-before-define
            deleteIgnoredStock.mutate(variables);
            // eslint-disable-next-line no-use-before-define
            deleteIgnoredStockfromCache();
            closeSnackbar(snackbarId);
          }}
          />
        ),
      });
    },
  });

  function deleteIgnoredStockfromCache() {
    setIsInIgnored(false);
    QueryClient.setQueryData(
      ['getIgnoredStocks'],
      (oldModel: any) => oldModel.filter((item: string) => item !== symbol),
    );
    QueryClient.setQueryData(
      ['getStockDetailedData', 'Ignored'],
      (oldModel: any) => oldModel.filter((item: StockDetailedData) => item.symbol !== symbol),
    );
  }

  const deleteIgnoredStock = useMutation((symbolToDelete: string) => (
    StockService.deleteIgnoredStock(symbolToDelete)), {
    onSuccess: (data, variables) => {
      enqueueSnackbar(`${variables} stock succesfully removed from ignored`, {
        action: (snackbarId: SnackbarKey) => (
          <SnackbarUndoButton undoClick={() => {
            putNewIgnoredStock.mutate(variables);
            putNewIgnoredStockToCache();
            closeSnackbar(snackbarId);
          }}
          />
        ),
      });
    },
  });

  function handleWatchlistClick() {
    if (isAuthenticated) {
      setIsWatchlistModalOpen(true);
    } else {
      notLoggedUserDialog.setIsDialogOpened(true);
      notLoggedUserDialog.setDialogText("You can't add stock to Watchlist");
    }
  }

  function handlePortfolioClick() {
    if (isAuthenticated) {
      setIsPortfolioModalOpen(true);
    } else {
      notLoggedUserDialog.setIsDialogOpened(true);
      notLoggedUserDialog.setDialogText("You can't add stock to Portfolio");
    }
  }

  function handleIgnoreClick() {
    if (isAuthenticated) {
      if (isInIgnored) {
        deleteIgnoredStock.mutate(symbol);
        deleteIgnoredStockfromCache();
      } else {
        putNewIgnoredStock.mutate(symbol);
      }
    } else {
      notLoggedUserDialog.setIsDialogOpened(true);
      notLoggedUserDialog.setDialogText("You can't add stock to Ignored");
    }
  }

  const deleteIcon = useMemo(() => (
    <IconButton className="actions-buttton" onClick={() => handleIgnoreClick()}>
      <Tooltip title='Delete'>
        {isInIgnored
          ? <DeleteIcon sx={{ color: '#C70101' }} />
          : <DeleteOutlineIcon />}
      </Tooltip>
    </IconButton>
  ), [isInIgnored]);

  const favouriteIcon = useMemo(() => (
    <IconButton className="actions-buttton" onClick={() => handleWatchlistClick()} disabled={isInIgnored}>
      <Tooltip title='Add to watchlist'>
        {isInWatchlists && !isInIgnored
          ? <VisibilityOutlined sx={{ color: '#FFB633' }} />
          : <VisibilityOutlined />}
      </Tooltip>
    </IconButton>
  ), [isInWatchlists, isInIgnored]);

  const portfolioIcon = useMemo(() => (
    <IconButton className="actions-buttton" onClick={handlePortfolioClick}>
      <Tooltip title='Add to portfolio'>
        {isInPortfolio
          ? <PersonOutlineOutlinedIcon sx={{ color: '#FFB633' }} />
          : <PersonOutlineOutlinedIcon />}
      </Tooltip>
    </IconButton>
  ), [isInPortfolio]);

  return (
    <>
      <Stack sx={{ width: '100%', height: '100%', gap: '2px' }} direction={'row'}>
        {deleteIcon}
        {favouriteIcon}
        {portfolioIcon}
      </Stack>
      <AddToPortfolioDialog isOpen={isPortfolioModalOpen} closePopup={() => setIsPortfolioModalOpen(false)} symbol={symbol} />
      <AddToWatchlistDialog isOpen={isWatchlistModalOpen} closePopup={() => setIsWatchlistModalOpen(false)} symbol={symbol} />
    </>
  );
}
