import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSpacingParams,
  MuiEvent,
} from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import React from 'react';
import CancelIcon from '@mui/icons-material/Close'; import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import EditIcon from '@mui/icons-material/Edit';
import {
  Paper, LinearProgress,
  Box,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import WatchlistService from '../../../services/watchlistService';
import EditToolbar from '../../Portfolio/transactions/components/EditToolbar';
import { ManageSearch } from '@mui/icons-material';
import CustomCell from '../../../components/analyticsGrid/CustomCell';
import useAlerts from '../../../hooks/useAlerts';
import { Parameter as AlertParameter } from '../../../components/analyticsGrid/Dialog/AddAlertDialog/parameterEnum';
import { Operator as AlertOperator } from '../../../components/analyticsGrid/Dialog/AddAlertDialog/operatorEnum';
import AlertService from '../../../services/alertService';

export default function AlertsGrid() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data: alerts, isFetching } = useAlerts();
  const [rows, setRows] = useState<GridRowModel[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rowIsInWaitingMode, setRowIsInWaitingMode] = useState<any>();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 15,
    page: 0,
  });

  useEffect(() => {
    if (alerts) {
      setRows(alerts.map((row: GridRowModel) => (
        { ...row }
      )));
    }
  }, [isFetching, alerts]);

  const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 8,
      bottom: params.isLastVisible ? 0 : 8,
    };
  }, []);

  function updateRows(newRows: GridRowModel[]) {
    queryClient.setQueryData(['getAlerts'], newRows);
    return newRows;
  }

  const deleteAlertById = useMutation((params: any) => (
    AlertService.deleteById(params.id)), {
    onError: (() => {
      enqueueSnackbar('Alert deletion failed, please refresh page', { variant: 'error' });
    }),
    onSuccess: (data, variables) => {
      queryClient.refetchQueries(['getAlerts']);
      enqueueSnackbar(`${variables.row.symbol} alert has been deleted succesfully`);
    },
  });

  const handleDeleteClick = (params: any) => () => {
    setRows((oldRows) => updateRows(oldRows.filter((row) => row.id !== params.id)));
    deleteAlertById.mutate(params);
  };

  const columns: GridColDef[] = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      editable: false,
      width: 250,
      flex: 0,
      cellClassName: 'actions-column',
      headerClassName: 'actions-column-header',
      getActions: (params: GridRowParams) => {
        return [
          <GridActionsCellItem
            icon={<Tooltip title='Delete'><DeleteIcon /></Tooltip>}
            label="Delete"
            onClick={handleDeleteClick(params)}
          />,
        ];
      },
    },
    {
      field: 'name',
      type: 'string',
      headerAlign: 'left',
      headerName: 'Name',
      editable: true,
      flex: 1,
      renderCell: (params: any) => {
        return <CustomCell value={params.value} />;
      }
    },
    {
      field: 'symbol',
      type: 'string',
      headerAlign: 'left',
      headerName: 'Symbol',
      editable: true,
      flex: 1,
      renderCell: (params: any) => {
        return <CustomCell value={params.value} />;
      }
    },
    {
      field: 'parameter',
      type: 'string',
      headerAlign: 'left',
      headerName: 'Parameter',
      editable: false,
      flex: 1,
      renderCell: (params: any) => {
        const parameterType = Object.values(AlertParameter).find(
          param => param.Value === params.value
        );
        return <CustomCell value={parameterType?.Description || params.value} />;
      }
    },
    {
      field: 'operator',
      type: 'string',
      headerAlign: 'left',
      headerName: 'Operator',
      editable: false,
      flex: 1,
      renderCell: (params: any) => {
        const parameterType = Object.values(AlertOperator).find(
          param => param.Value === params.value
        );
        return <CustomCell value={parameterType?.Description || params.value} />;
      }
    }
    ,
    {
      field: 'threshold',
      type: 'number',
      headerAlign: 'left',
      headerName: 'Threshold',
      editable: false,
      flex: 1,
      renderCell: (params: any) => {
        return <CustomCell value={params.value} />;
      }
    },
    {
      field: 'expiration',
      type: 'date',
      headerAlign: 'left',
      headerName: 'Expiration',
      editable: false,
      flex: 1,
      valueGetter: (params: any) => new Date(params.value),
      renderCell: (params: any) => {
        return <CustomCell value={params.value} />;
      }
    }
  ];

  return (
    <Paper sx={{
      width: '95%', flexFlow: 'column', flex: '0 1 auto'
    }}
    >
      <DataGridPro
        rows={rows}
        columns={columns}
        density="standard"
        editMode="row"
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[5, 10, 15]}
        autoHeight
        rowModesModel={rowModesModel}
        getRowSpacing={getRowSpacing}
        onRowModesModelChange={(newModel) => (
          setRowModesModel((oldModel) => ({ ...oldModel, ...newModel }))
        )}
        getRowId={(row) => row.id}
        components={{
          LoadingOverlay: LinearProgress,
        }}
        loading={isFetching}
        componentsProps={{
          toolbar: {
            setRows, setRowModesModel, setRowIsInWaitingMode,
          },
        }}
      />
    </Paper>
  );
}
