import React, { useState } from 'react';
import { Box, Button, IconButton, InputBase, Paper, Typography } from '@mui/material';
import StocksSearch from '../../components/StockSearch/StocksSearch';
import ROUTES from '../../constants/routes';
import { useLocation } from 'react-router-dom';

interface UnauthenticatedNavProps {
    navigate: (path: string) => void;
    instance: {
        loginRedirect: (request: any) => void;
    };
    loginRequest: any;
    isBasePath: boolean;
    DocumentsMenu: React.ComponentType;
}

const UnauthorizedUserMenu: React.FC<UnauthenticatedNavProps> = ({
    navigate,
    instance,
    loginRequest,
    isBasePath,
    DocumentsMenu
}) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const [searchMenuIsOpen, setSearchMenuIsOpen] = useState<boolean>(false);

    const location = useLocation();
    const showHeaderSearch = location.pathname !== '/' && !location.pathname.includes('stockScreener/screener');

    function handleClickMenuItem(companySymbol: string) {
        if (companySymbol === '') return;
        navigate(ROUTES.stockDetailedData(companySymbol));
        setSearchMenuIsOpen(() => false);
    }

    function handleChangeTextFieldValue(e: React.ChangeEvent<HTMLInputElement>) {
        setSearchValue(() => e.target.value);
        setSearchMenuIsOpen(() => true);
    }

    return (
        <Box sx={{
            display: 'flex',
            width: '100%',
            flexDirection: { xs: 'column', xl: 'row' },
            alignItems: { xs: 'flex-end', xl: 'center' },
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: { xs: 'flex-end', xl: 'flex-start' },
                gap: 2,
                width: { xs: '100%', xl: 'auto' }
            }}>
                {showHeaderSearch && <Box sx={{ alignSelf: 'left' }}>
                    <StocksSearch
                        inputValue={searchValue}
                        onClickMenuItem={handleClickMenuItem}
                        searchMenuIsOpen={searchMenuIsOpen}
                        setSearchMenuIsOpen={setSearchMenuIsOpen}
                    >
                        <Paper
                            style={{
                                borderRadius: '15px',
                                boxShadow: '2px 8px 12.4px #00000008', // Optional: add a shadow for better visibility,
                                height: '36px',
                                width: '240px',
                                border: '1px solid #0000001A',
                                marginTop: '2px'
                            }}

                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '5px 10px 5px 0',
                                position: 'relative',
                                alignItems: 'center',
                                backgroundColor: '#F9F9F912'
                            }}
                        >
                            <InputBase
                                value={searchValue}
                                placeholder="Search for your stock..."
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeTextFieldValue(e)}
                                sx={{ width: '400px', marginLeft: '20px', color: 'black' }}
                            />
                        </Paper>
                    </StocksSearch>
                </Box>}
                <IconButton
                    size="large"
                    aria-label="pricing"
                    onClick={() => navigate('pricing')}
                    color="inherit"
                    sx={{
                        borderRadius: 0,
                        minWidth: 'auto'
                    }}
                >
                    <Typography sx={{ overflow: 'hidden', fontWeight: '600' }} variant="body2">
                        Pricing
                    </Typography>
                </IconButton>

                <Box sx={{ minWidth: 'auto' }}>
                    <DocumentsMenu />
                </Box>

                <Button
                    variant="text"
                    sx={{
                        minWidth: 'auto',
                        padding: '12px',
                        color: 'inherit'
                    }}
                    onClick={() => instance.loginRedirect(loginRequest)}
                >
                    <Typography sx={{
                        fontWeight: '600',
                        fontSize: '0.874rem',
                        textTransform: 'none',
                    }}>
                        Login
                    </Typography>
                </Button>
            </Box>

            {/* Sign up button that can wrap */}
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                width: { xs: '100%', xl: 'auto' },
                justifyContent: { xs: 'flex-end', xl: 'flex-start' }
            }}>
                {!isBasePath && <Button
                    sx={{
                        padding: { xs: '3px 50px', xl: '12px 50px' },
                        textTransform: 'none',
                        borderRadius: '50px',
                        background: 'linear-gradient(90deg, #330867 0%, #30CFD0 100%)',
                        color: 'white',
                        minWidth: 'auto'
                    }}
                    variant="contained"
                    size="medium"
                    onClick={() => instance.loginRedirect(loginRequest)}
                    aria-label="Sign up button"
                >
                    Sign up for free
                </Button>}
            </Box>
        </Box>
    );
};

export default UnauthorizedUserMenu;