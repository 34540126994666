import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react";

export default function FAQ() {
    const [expanded, setExpanded] = useState<number>();

    const handleChange = (panel: any) => (event: any, isExpanded: any) => {
        setExpanded(isExpanded ? panel : false);
    };
    const questions = [
        {
            id: 1,
            question: "Can I cancel my subcsription anytime by myself whenever I decide I'm done",
            answer: 'Yes, you can.'
        },
        {
            id: 2,
            question: "What happens with my early bird paid access after the promotion period will end?",
            answer: "All accounts who decide to participate in early bird subscription will be beneficial for live as long as the subscription won't be cancelled."
        },
        {
            id: 3,
            question: "I need higher capabilities (e.g. more alerts) than premium plan offers. What can I do?",
            answer: "Contact us via support@stocktradeiq.com to receive tailored offer for your needs as an add-in package."
        },
        {
            id: 4,
            question: "Is the above list of features a closed list or should users expect new functionalities in the future?",
            answer: "StockTradeIQ is now offering preferential early-bird pricing for a limited time. Afterward, the app will grow continuously offering expanding functionalities and rewarding users who decided to join us at early stages."
        },
        {
            id: 5,
            question: "I want to try the app before paying for it to check if the features works as I expect. Can I do it?",
            answer: "Yes, you can use free trial for choosen plan after which your payment will be captured based on chosen billing option. If you decided that you are not interested in the functionalities you can cancel the subscription before the first payment and no fees will be taken from the account. After the payment is processed you are eligible to use the app for the payed period. You can still cancel the subscription to avoid future payments."
        }
    ]

    return (
        <Box
            sx={{
                width: '100%',
                minHeight: '90vh',
                background: `white`,
                paddingTop: '100px'
            }}>
            <Typography sx={{ fontWeight: '600', fontSize: '42px', textAlign: 'center', marginBottom: '50px' }}>
                FAQ
            </Typography>
            <Box sx={{ maxWidth: '93%', margin: '0 auto' }}>
                {questions.map((item) => (
                    <Accordion
                        key={item.id}
                        expanded={expanded === item.id}
                        onChange={handleChange(item.id)}
                        sx={{
                            marginBottom: '60px',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            '&:before': {
                                display: 'none',
                            },
                            borderRadius: '8px',
                            '&.Mui-expanded': {
                                marginBottom: '16px',
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${item.id}-content`}
                            id={`${item.id}-header`}
                            sx={{
                                '& .MuiAccordionSummary-content': {
                                    margin: '16px 0',
                                }
                            }}
                        >
                            <Typography sx={{ fontWeight: '600', fontSize: '22px' }}>
                                {item.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                paddingX: '24px',
                                paddingBottom: '24px'
                            }}
                        >
                            <Typography sx={{ fontWeight: '400', fontSize: '16px' }}>
                                {item.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </Box>
    )
}