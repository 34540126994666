import { Box, TableCell } from "@mui/material";
import Humanize from "../../services/humanize";

export default function CustomCell(params: any) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        ...(params.align && { justifyContent: params.align === 'center' ? 'center' : 'flex-start' }),
        background: "rgba(240, 240, 240, 0.4)",
        width: '100%',
        height: '80%', 
        paddingLeft: params.align === 'center' ? '0px' : '10px',
        paddingRight: params.align === 'center' ? '0px' : '40px',
        borderRadius: '8px'
      }}
    >
      <TableCell
        {...params}
        sx={{
          height: '100%',
          padding: 0,
          display: 'flex',
          alignItems: 'center',
          ...(params.align && { justifyContent: params.align === 'center' ? 'center' : 'flex-start' }),
          width: '100%'
        }}
      >
        {Humanize.readableValue(params.value)}
      </TableCell>
    </Box>
  );
};