import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import IntelligentInvestorIcon from '../../../assets/intelligentInvestor.png';
import DividndInvestorIcon from '../../../assets/dividendInvestor.png';
import IndividualActiveInvestor from '../../../assets/individualActiveInvestor.png';

export default function ForWho() {
    return (
        <Box
            sx={{
                width: '100%',
                minHeight: '80vh',
                background: `white`
            }}
        >
            <Grid container sx={{ padding: '3%' }} spacing={10}>
                <Grid item xs={12}>
                    <Typography sx={{ fontWeight: '600', fontSize: '42px', lineHeight: '67px', textAlign: 'center' }}>
                        Who is StockTradeIQ for?
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Paper sx={{ minHeight: '370px', width: '100%', background: 'white' }}
                        elevation={7}>
                        <Stack
                            direction="column"
                            alignItems="center"
                            style={{
                                width: '100%',
                                paddingLeft: '10%',
                                paddingRight: '10%',
                                paddingTop: '5%'
                            }}
                        >
                            <Box sx={{ padding: '10px' }}>
                                <div>
                                    <img src={IntelligentInvestorIcon} style={{}} />
                                </div>
                            </Box>

                            <Typography
                                style={{
                                    fontSize: '28px',
                                    fontWeight: 600,
                                    lineHeight: '38px',
                                    textAlign: 'center',
                                    marginBottom: '16px'
                                }}
                            >
                                Intelligent investors
                            </Typography>

                            <Typography
                                style={{
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    textAlign: 'center',
                                    width: '100%'
                                }}
                            >
                                Our tool has been developed to support value investing to the fullest. If you are interested in a classic approach based on fundamental analysis, you have come to the right place. We design our solution and build dedicated functionalities to support this style of investing.
                            </Typography>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Paper sx={{ minHeight: '370px', width: '100%', background: 'white' }}
                        elevation={7}>
                        <Stack
                            direction="column"
                            alignItems="center"
                            style={{
                                width: '100%',
                                paddingLeft: '10%',
                                paddingRight: '10%',
                                paddingTop: '5%'
                            }}
                        >
                            <Box sx={{ padding: '10px' }}>
                                <div>
                                    <img src={DividndInvestorIcon} style={{}} />
                                </div>
                            </Box>

                            <Typography
                                style={{
                                    fontSize: '28px',
                                    fontWeight: 600,
                                    lineHeight: '38px',
                                    textAlign: 'center',
                                    marginBottom: '16px'
                                }}
                            >
                                Dividend investors
                            </Typography>

                            <Typography
                                style={{
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    textAlign: 'center',
                                    width: '100%'
                                }}
                            >
                                Dividend investors will find special functionalities in our dedicated tool, which were created with the idea of ​​finding the most suitable investment opportunities in mind. If your approach is long-term and you need a tool that will support both your search for investment opportunities and tracking your investment portfolio, you have come to the right place.
                            </Typography>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Paper sx={{ minHeight: '370px', width: '100%', background: 'white' }}
                        elevation={7}>
                        <Stack
                            direction="column"
                            alignItems="center"
                            style={{
                                width: '100%',
                                paddingLeft: '10%',
                                paddingRight: '10%',
                                paddingTop: '5%'
                            }}
                        >
                            <Box sx={{ padding: '10px' }}>
                                <div>
                                    <img src={IndividualActiveInvestor} style={{}} />
                                </div>
                            </Box>

                            <Typography
                                style={{
                                    fontSize: '28px',
                                    fontWeight: 600,
                                    lineHeight: '38px',
                                    textAlign: 'center',
                                    marginBottom: '16px'
                                }}
                            >
                                Individual active investors
                            </Typography>

                            <Typography
                                style={{
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    textAlign: 'center',
                                    width: '100%'
                                }}
                            >
                                Our tool aims to be a suitable place for both seasoned investors and those who are just starting their adventure with investing. Considering the simplicity of use and the affordable price, we want to focus on supporting individual investors in the active search for opportunities on the capital market.
                            </Typography>
                        </Stack>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}
