import clsx from 'clsx';
import {
  Drawer, useMediaQuery, useTheme, Box,
  IconButton,
} from '@mui/material';
import { useDrawerContext } from '../../contexts/DrawerContext';
import MenuItemsList from './MenuItemsList';
import drawer from '../../constants/drawer';
import './Drawer.css';
import useDrawerStyles from '../styles/useDrawerStyles';
import { useAdsContext } from '../../contexts/AdsContext';
import { ChevronLeft, Menu } from '@mui/icons-material';
import useToolbarStyles from '../styles/useToolbarStyles';

function CustomDrawer() {
  const { isOpened, toggleIsOpened } = useDrawerContext();
  const theme = useTheme();
  const toolbarClasses = useToolbarStyles();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useDrawerStyles();
  const adsContext = useAdsContext();

  return (
    <Box>
      <Box sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: theme.zIndex.appBar + 1,



      }}>
        <IconButton
          color="inherit"
          onClick={() => toggleIsOpened(!isOpened)}
          classes={{ root: toolbarClasses.icon }}
          sx={{ paddingLeft: { xs: '10px', sm: '15px'}, paddingTop: {xs: '10px', sm: '15px'} }}
        >
          {isOpened ? <ChevronLeft sx={{ fontSize: {xs: '2.5rem', sm: '1.5rem'} }} /> : <Menu sx={{ fontSize: {xs: '2.5rem', sm: '1.5rem'} }} />}
        </IconButton>
      </Box>
      <Drawer
        className={clsx('drawer', { 'temporary-drawer': !isLargeScreen })}
        variant={isLargeScreen ? 'permanent' : 'temporary'}
        open={!!(!isLargeScreen && isOpened)}
        onClose={() => toggleIsOpened(!isOpened)}
        elevation={20}
        classes={{ paper: classes.drawerPaper }}
        sx={{
          width: isOpened ? drawer.OpenDrawerWidth : drawer.CloseDrawerWidth,
          zIndex: theme.zIndex.appBar,
          '& .MuiDrawer-paper': {
            width: isOpened ? drawer.OpenDrawerWidth : drawer.CloseDrawerWidth,
            boxShadow: '5px 0 15px -5px rgba(0,0,0,0.3)',
            position: 'fixed',
            transition: theme.transitions.create(['width'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }
        }}
      >
        <Box sx={{ paddingLeft: isOpened ? '20px' : '0' }}>
          <Box className="drawer-elems" sx={{ height: '100%', paddingTop: '45px' }}>
            <MenuItemsList />
          </Box>
          {adsContext.adsAreVisible && <Box className="drawer-ads center-items ad">ads</Box>}
        </Box>
      </Drawer>
    </Box>
  );
}

export default CustomDrawer;
