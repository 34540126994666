import {
  Box, Paper, Typography,
} from '@mui/material';
import React from 'react';
import useGlobalStyles from '../styles/useGlobalStyles';

interface SupportFormPropsModel{
    header: string,
    subheader: string,
}

export default function SupportFormWrapper({
  header, subheader, children,
}:React.PropsWithChildren<SupportFormPropsModel>) {
  const globalClasses = useGlobalStyles();

  return (
    <Paper sx={{
      width: '100%', height: '100%', minWidth: 'min(90%, 500px)', position: 'relative', textAlign: 'center', display: 'flex', flexDirection: 'column', padding: '5%'
    }}
    >
      <Box sx={{ width: '100%' }} className={globalClasses.header}>
        <Typography variant="h1" sx={{ padding: '10px 0px 10px 0px', fontSize: '2rem', fontWeight: '400', textAlign: 'center' }}>
          {header}
        </Typography>
        <Typography variant="h2" sx={{ padding: '0px 10px 0px 10px', fontSize: '1rem', fontWeight: '50', textAlign: 'center' }}>
          {subheader}
        </Typography>
      </Box>
      <Box sx={{ minHeight: '300px' }}>
        {children}
      </Box>
    </Paper>
  );
}
