import { QueryClient } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import utils from '../common/utils';
import StockDetailedData from '../models/stockDetailedData';

const getStocksDetailedData = async (
  exchanges: string[],
  indexes: string[],
  onlyFavourite: boolean,
  onlyIgnored: boolean,
  queryClient: QueryClient,
) => {
  let response = { data: [] as StockDetailedData[] } as AxiosResponse<StockDetailedData[], any>;
  let filteredExchanges = exchanges.filter((exchange) => !queryClient.getQueryData(['exchange', exchange]));
  indexes = indexes.filter((index) => !queryClient.getQueryData(['index', index]));
  if (onlyFavourite) {
    const URL = `${process.env.REACT_APP_SERVER_URL}/UserStock/favouriteDetailedData`;
    response = await axios.get<StockDetailedData[]>(URL);
  } else if (onlyIgnored) {
    const URL = `${process.env.REACT_APP_SERVER_URL}/UserStock/IgnoredDetailedData`;
    response = await axios.get<StockDetailedData[]>(URL);
  } else {
    let data = [] as any;
    if (filteredExchanges.length !== 0) {
      const URL = `${process.env.REACT_APP_SERVER_URL}/Stock/getDetailedDataByExchanges`;
      response = await axios.post<StockDetailedData[]>(URL, { filtered: filteredExchanges, all: exchanges});
      data = response.data;
    }
    if (indexes.length !== 0) {
      const URL = `${process.env.REACT_APP_SERVER_URL}/Stock/getDetailedDataByIndexes`;
      response = await axios.post<StockDetailedData[]>(URL, indexes);
      data = [...response.data, ...data];
    }
    response.data = data;
  }
  let data = response.data.map((elem: StockDetailedData) => (
    {
      ...elem,
      payoutRatioTTM: elem.payoutRatioTTM * 100,
      dividendYieldPercentageTTM: elem.dividendYieldPercentageTTM * 100,
      freeCashFlowYieldTTM: elem.freeCashFlowYieldTTM !== null
        ? elem.freeCashFlowYieldTTM * 100 : elem.freeCashFlowYieldTTM,
      roe: elem.roe !== null
        ? elem.roe * 100 : elem.roe,
      roic: elem.roic !== null
        ? elem.roic * 100 : elem.roic,
      grossProfitMarginTTM: elem.grossProfitMarginTTM !== null
        ? elem.grossProfitMarginTTM * 100 : elem.grossProfitMarginTTM,
      netProfitMarginTTM: elem.netProfitMarginTTM !== null
        ? elem.netProfitMarginTTM * 100 : elem.netProfitMarginTTM,
      returnOnAssetsTTM: elem.returnOnAssetsTTM !== null
        ? elem.returnOnAssetsTTM * 100 : elem.returnOnAssetsTTM,
      earningsYieldTTM: elem.earningsYieldTTM !== null
        ? elem.earningsYieldTTM * 100 : elem.earningsYieldTTM,
    } as StockDetailedData));
  data = utils.getUniqueListBy(data, 'symbol');
  return data;
};

const getFavouriteStocks = async () => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/UserStock/favourite`;
  const response = await axios.get(URL);
  return response.data;
};

const getSimilarCompanies = async (symbol: string) => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/Stock/getDetailedDataForPeers?symbol=${symbol}`;
  const response = await axios.get(URL);
  return response.data;
};

const putNewFavouriteStock = async (symbol: string) => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/UserStock/favourite?symbol=${symbol}`;
  const result = await axios.put(URL);
  return result;
};

const deleteFavouriteStock = async (symbol: string) => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/UserStock/favourite?symbol=${symbol}`;
  const result = await axios.delete(URL);
  return result;
};

const getIgnoredStocks = async () => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/UserStock/ignored`;
  const response = await axios.get(URL);
  return response.data;
};

const putNewIgnoredStock = async (symbol: string) => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/UserStock/ignored?symbol=${symbol}`;
  const result = await axios.put(URL);
  return result;
};

const deleteIgnoredStock = async (symbol: string) => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/UserStock/ignored?symbol=${symbol}`;
  const result = await axios.delete(URL);
  return result;
};

const StockService = {
  getStocksDetailedData,
  getFavouriteStocks,
  putNewFavouriteStock,
  deleteFavouriteStock,
  getIgnoredStocks,
  putNewIgnoredStock,
  deleteIgnoredStock,
  getSimilarCompanies
};
export default StockService;
