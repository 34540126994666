import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import useStockKeyTakeaways from '../../../hooks/useStockKeyTakeaways';
import useGlobalStyles from '../../../styles/useGlobalStyles';
import Loader from '../../Loader';
import Badges from '../components/Badges';
import Indicators from '../components/Indicators';
import STIScore from '../components/STIScore';
import StockUnauthorizedAdvancedKeyTakeaways from './StockUnauthorizedAdvancedKeyTakeaways';

interface StockAdvancedKeyTakeawaysPropsModel {
  symbol: string,
  paperHeight: string
}

export default function StockAdvancedKeyTakeaways(props: StockAdvancedKeyTakeawaysPropsModel) {
  const { symbol, paperHeight } = props;
  const { data: stockKeyTakeaways, isLoading, isForbidden } = useStockKeyTakeaways(symbol);

  const globalClasses = useGlobalStyles();

  function backgroundColor() {
    if (!stockKeyTakeaways) {
      return '#FFFFFF';
    }


    if (stockKeyTakeaways.score < 8) {
      return '#F8CFE0';
    } else if (stockKeyTakeaways.score >= 8 && stockKeyTakeaways.score <= 11) {
      return '#FFEFD6';
    } else if (stockKeyTakeaways.score > 11) {
      return '#DCFFD6';
    }
  }

  function stiScoreColor() {
    if (!stockKeyTakeaways) {
      return '#FFFFFF';
    }


    if (stockKeyTakeaways.score < 8) {
      return '#E53935';
    } else if (stockKeyTakeaways.score >= 8 && stockKeyTakeaways.score <= 11) {
      return '#EF6C00';
    } else if (stockKeyTakeaways.score > 11) {
      return '#4CAF50';
    }
  }


  return (
    isForbidden ?
    <StockUnauthorizedAdvancedKeyTakeaways/> :
    <Paper sx={{
      width: '100%', height: paperHeight, backgroundColor: backgroundColor(), textAlign: 'center', display: 'flex', flexDirection: 'column',
    }} elevation={7}
    >
      <Typography
        sx={{
          padding: '15px 50px 5px 15px;', width: '100%', display: 'flex', justifyContent: 'center', fontSize: '16px', fontWeight: 700
        }}
        variant="h6"
        className={globalClasses.header}
      >
        KEY TAKEAWAYS
      </Typography>
      {isLoading || !stockKeyTakeaways
        ? (
          <Box sx={{ width: '100%', minHeight: '150px', flexGrow: 1 }} className="center-items">
            <Loader />
          </Box>
        )
        : (
          <Box sx={{
            display: 'flex', flexGrow: 1, flexDirection: 'column', gap: '15px', alignItems: 'center',
          }}
          >
            <Grid container sx={{ width: '100%', height: '100%' }}>
              <Grid item xs={3}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Box sx={{ marginLeft: '10%', marginRight: '10%', marginTop: '15%', height: '44px' }}>
                      <STIScore
                        color={stiScoreColor()} score={stockKeyTakeaways.score}
                      />
                    </Box>
                  </Grid>
                  <Grid item sx={{ width: '100%', height: '100%' }}>
                    <Box sx={{ height: '100%', width: '100%' }}>
                      <Badges
                        showDividendBadge={stockKeyTakeaways.dividendBadge}
                        showGrowthBadge={stockKeyTakeaways.growthBadge}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={9} sx={{ height: '100%' }}>
                {stockKeyTakeaways.indicators.length !== 0 && (
                  <Indicators
                    indicators={stockKeyTakeaways.indicators}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        )}
    </Paper>
  );
}
