import axios from "axios";

const postNewAlert = async (data: Object) => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/Alert/Create`;
  const result = await axios.post(URL, data);
  return result;
};

const getAll = async () => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/Alert`;
  const result = await axios.get(URL);
  return result.data;
};

const deleteById = async (id: number) => {
  const URL = `${process.env.REACT_APP_SERVER_URL}/Alert?id=${id}`;
  await axios.delete(URL);
};

const AlertService = {
  postNewAlert,
  getAll,
  deleteById
};
export default AlertService;