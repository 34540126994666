import { Height } from "@mui/icons-material";
import { Box, Button, Divider, Grid, Link, Typography } from "@mui/material";
import { loginRequest } from "../../../authConfig";
import { useMsal } from "@azure/msal-react";
import decreaseInvestmentRiskImage from "../../../assets/decreaseInvestmentRisk.png"
import uniqueAlertsImage from "../../../assets/uniqueAlerts.png"
import tailoredDatagridImage from "../../../assets/tailoredDataGrid.png"

export default function DedicatedFunctionsForEffectiveWork() {
    const imageWidthPercent = 100;

    const { instance } = useMsal();

    return (
        <Box
            sx={{
                width: '100%',
                minHeight: '100vh',
                position: 'relative',
                overflow: 'hidden',
                background: `white`
            }}
        >
            <Grid container spacing={10} style={{ paddingLeft: '10%', paddingRight: '5%', marginBottom: '120px', marginTop: '20px', position: 'relative' }}>
                <Grid item xs={12}>
                    <Typography
                        sx={{
                            fontSize: '64px',
                            fontFamily: 'Calibre, sans-serif',
                            fontWeight: '500',
                            marginBottom: '16px',
                            color: 'black',
                            textAlign: 'center',
                            zIndex: '30',
                            position: 'relative'
                        }}
                    >
                        Dedicated functions for effective work
                    </Typography>
                </Grid>
                <Grid item xs={12} md={5} style={{ marginBottom: '60px' }}>
                    <Box
                        sx={{
                            position: 'relative',
                            width: `${imageWidthPercent}%`,
                            paddingBottom: `${(1391 / 2455) * imageWidthPercent}%`,
                        }}
                    >
                        <Box
                            component="img"
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                zIndex: 20
                            }}
                            alt="Decrease investment risk with our key takeaways"
                            src={decreaseInvestmentRiskImage}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: -45,
                                right: -55,
                                width: '10000px',
                                height: '130%',
                                objectFit: 'cover',
                                background: '#DBE6FA',
                                zIndex: 10,
                                borderRadius: '20px'
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Box sx={{ minHeight: '200px' }}>
                        <Typography sx={{ color: 'black', fontSize: '40px', fontWeight: '500', lineHeight: '48px', marginBottom: '20px' }}>
                            Original scoring mechanism
                        </Typography>
                        <Typography sx={{ color: 'black', fontSize: '16px', fontWeight: '400', lineHeight: '26px' }}>
                            Stock screener with original scoring and key takeaway mechanism. Search for investment opportunities worldwide and save time for analysis by using our key takeaways and scoring to quickly identify the most interesting insights regarding a browsed company based on all the available historical data.
                        </Typography>
                        <Link
                            href="/blog/original-scoring-mechanism"
                            sx={{
                                color: 'rgb(48, 207, 208)',
                                textDecoration: 'none',
                            }}
                        >
                            LEARN MORE
                        </Link>
                    </Box>
                    <Divider
                        sx={{
                            backgroundcolor: 'black',
                            width: '65%',
                            height: '2px',
                            marginLeft: 0,
                            marginTop: '20px',
                            marginBottom: '20px',
                            opacity: 0.7
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={7}>
                    <Box sx={{ minHeight: '200px', paddingTop: { xs: '0', lg: '15%' }, position: 'relative' }}>
                        <Box sx={{ position: 'relative', zIndex: 20 }}>  {/* Wrapper to ensure text stays above background */}
                            <Typography sx={{ color: 'black', fontSize: '40px', fontWeight: '500', lineHeight: '48px', marginBottom: '20px' }}>
                                Unique fundamental alerts
                            </Typography>
                            <Typography sx={{ color: 'black', fontSize: '16px', fontWeight: '400', lineHeight: '26px' }}>
                                Price and fundamental alerts elevate value investing to a new level. Whenever you browse for a company that will have certain indicators at an expected level, now you can save time by setting up alerts that will help you figure out whenever a company of your interest reaches the expected price level or business performance.
                            </Typography>
                            <Link
                                href="/blog/fundamental-alerts"
                                sx={{
                                    color: 'rgb(48, 207, 208)',
                                    textDecoration: 'none',
                                }}
                            >
                                LEARN MORE
                            </Link>
                        </Box>
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: -80,
                                right: -5000,
                                width: '10000px',
                                height: { xs: '150%', lg: '110%' },
                                objectFit: 'cover',
                                background: '#EBD3F5',
                                zIndex: 10,
                                borderRadius: '20px'
                            }}
                        />
                    </Box>
                    <Divider
                        sx={{
                            backgroundcolor: 'black',
                            width: '65%',
                            height: '2px',
                            marginLeft: 0,
                            marginTop: '20px',
                            marginBottom: '20px',
                            opacity: 0.7
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={5}>
                    <Box
                        sx={{
                            position: 'relative',
                            width: `${imageWidthPercent}%`,
                            paddingBottom: `${imageWidthPercent}%`,
                            zIndex: 20
                        }}
                    >
                        <Box
                            component="img"
                            sx={{
                                position: 'absolute',
                                top: -90,
                                left: 0,
                                minWidth: '400px',
                                width: '110%',
                                height: '100%',
                                objectFit: 'cover'
                            }}
                            alt="Unique alerts image"
                            src={uniqueAlertsImage}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Box
                        sx={{
                            position: 'relative',
                            width: `${imageWidthPercent}%`,
                            paddingBottom: `${(350 / 642) * imageWidthPercent}%`,
                        }}
                    >
                        <Box
                            component="img"
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                zIndex: 20
                            }}
                            alt="Tailored datagrid image"
                            src={tailoredDatagridImage}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: -45,
                                right: -55,
                                width: '10000px',
                                height: '130%',
                                objectFit: 'cover',
                                background: '#DBE6FA',
                                zIndex: 10,
                                borderRadius: '20px'
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Box sx={{ minHeight: '200px' }}>
                        <Typography sx={{ color: 'black', fontSize: '40px', fontWeight: '500', lineHeight: '48px', marginBottom: '20px' }}>
                            Stock screener tailored for dividend investors
                        </Typography>
                        <Typography sx={{ color: 'black', fontSize: '16px', fontWeight: '400', lineHeight: '26px' }}>
                            Stock screener that has been tailored to help you do your research using value investing principles, especially for dividend companies. Moreover, as a dividend investor, you can set your dividend payout goal and analyze investment opportunities by the goal.
                        </Typography>
                        <Link
                            href="/blog/stock-screener-for-dividend-investing"
                            sx={{
                                color: 'rgb(48, 207, 208)',
                                textDecoration: 'none',
                            }}
                        >
                            LEARN MORE
                        </Link>
                    </Box>
                    <Divider
                        sx={{
                            backgroundcolor: 'black',
                            width: '65%',
                            height: '2px',
                            marginLeft: 0,
                            marginTop: '20px',
                            marginBottom: '20px',
                            opacity: 0.7
                        }}
                    />
                    <Button
                        style={{
                            padding: '12px 50px',
                            margin: '10px',
                            textTransform: 'none',
                            borderRadius: '10px',  // Rounded edges
                            background: '#30CFD0',  // Gradient background
                            color: 'white',
                            fontSize: '20px',
                            boxShadow: '2px 8px 12.4px 1px #00000021'
                        }}
                        variant="contained"
                        size="large"
                        onClick={() => instance.loginRedirect(loginRequest)}
                        aria-label="I’m starting for free button"
                    >
                        I’m starting for free
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}