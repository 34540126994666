/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { SnackbarProvider } from 'notistack';
import {
  Box,
  Backdrop,
  CircularProgress,
  useMediaQuery,
  useTheme,
  ThemeProvider,
  CssBaseline,
  Theme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { EventType, InteractionType } from '@azure/msal-browser';
import CustomDrawer from './components/Drawer';
import Header from './components/Header';
import './Layout.css';
import { useLoaderContext } from '../contexts/LoaderContext';
import { useDrawerContext } from '../contexts/DrawerContext';
import drawer from '../constants/drawer';
import { useColorModeContext } from '../contexts/ColorModeContext';
import getGlobalTheme from '../themes/globalTheme';
import DefaultSnackbar from '../components/Snackbar/DefaultSnackbar';
import { useDialogContext } from '../contexts/DialogContext';
import { b2cPolicies } from '../authConfig';
import NotLoggedUserDialog from './components/NotLoggedUserDialog';
import { AdsContextProvider } from '../contexts/AdsContext';
import { MutationsContextProvider } from '../contexts/MutationsContext';
import FreeResourcesExceededDialog from './components/FreeResourcesExceededDialog';
import { useFreeResourcesExceededDialogContext } from '../contexts/FreeResourcesExceededDialogContext';
import TagManager from '@sooro-io/react-gtm-module';

interface MyProps { }

function Layout({ children }: React.PropsWithChildren<MyProps>) {
  const { instance } = useMsal();

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const callbackId = instance.addEventCallback((event: any) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event.error && event.error.errorMessage.indexOf('AADB2C90118') > -1) {
          if (event.interactionType === InteractionType.Redirect) {
            //  instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
          } else if (event.interactionType === InteractionType.Popup) {
            //  instance.loginPopup(b2cPolicies.authorities.forgotPassword)
            //  .catch(e => {
            //    return;
            //  });
          }
        }
      }

      if (
        event.eventType === EventType.LOGIN_SUCCESS
        || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ) {
        if (event?.payload?.account) {
          instance.setActiveAccount(event?.payload?.account);

          if (event.eventType === EventType.LOGIN_SUCCESS && event?.payload?.idTokenClaims?.newUser) {
            TagManager.dataLayer({
              dataLayer: {
                  event: 'user_registered',
              },
          })
          }
        }
        if (event?.payload) {
          if (event.payload.idTokenClaims.acr === b2cPolicies.names.forgotPassword) {
            window.alert(
              'Password has been reset successfully. \nPlease sign-in with your new password.',
            );
            return instance.logout();
          }
          if (event.payload.idTokenClaims.acr === b2cPolicies.names.editProfile) {
            window.alert('Profile has been edited successfully. \nPlease sign-in again.');
            return instance.logout();
          }
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  const { isLightMode } = useColorModeContext();
  const { isLoaderOpened } = useLoaderContext();
  const { isOpened: isDrawerOpened } = useDrawerContext();
  const [globalTheme, setGlobalTheme] = useState<Theme>(getGlobalTheme(isLightMode));
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const notLoggedUserDialogContext = useDialogContext();
  const resourcesExceededDialogContext = useFreeResourcesExceededDialogContext();

  useEffect(() => {
    setGlobalTheme(getGlobalTheme(isLightMode));
  }, [isLightMode]);

  function getDrawerWidth() {
    if (isLargeScreen) {
      if (isDrawerOpened) {
        return drawer.OpenDrawerWidth;
      }

      return drawer.CloseDrawerWidth;
    }

    return '0px';
  }

  return (
    <ThemeProvider theme={globalTheme}>
      <CssBaseline />
      <MutationsContextProvider>
        <AdsContextProvider>
          <SnackbarProvider
            maxSnack={5}
            dense
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            Components={{
              default: DefaultSnackbar,
            }}
          >
            <Box sx={{ bgcolor: 'background.default' }} className="root">
              <Box className="container">
                <CustomDrawer />
                <Header />
                <Box
                  className="main"
                  component="main"
                  sx={{
                    width: `calc(100% - ${getDrawerWidth()})`,
                    minHeight: '100vh'
                  }}
                >
                  {children}
                </Box>
                <NotLoggedUserDialog
                  isDialogOpened={notLoggedUserDialogContext.isDialogOpened}
                  setIsDialogOpened={notLoggedUserDialogContext.setIsDialogOpened}
                  dialogText={notLoggedUserDialogContext.dialogText}
                />
                <FreeResourcesExceededDialog
                  isDialogOpened={resourcesExceededDialogContext.isDialogOpened}
                  setIsDialogOpened={resourcesExceededDialogContext.setIsDialogOpened}
                  dialogText={resourcesExceededDialogContext.dialogText}
                />
                <Backdrop
                  sx={{ color: '#fff', zIndex: theme.zIndex.drawer - 1 }}
                  open={isLoaderOpened}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </Box>
            </Box>
          </SnackbarProvider>
        </AdsContextProvider>
      </MutationsContextProvider>
    </ThemeProvider>
  );
}

export default Layout;
