import {
  AppBar, IconButton, Toolbar, Box, Button, Theme,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { ChevronLeft, Menu } from '@mui/icons-material';
import { loginRequest } from '../../authConfig';
import { useDrawerContext } from '../../contexts/DrawerContext';
import './Header.css';
import header from '../../constants/header';
import useToolbarStyles from '../styles/useToolbarStyles';
import useHeaderStyles from '../styles/useHeaderStyles';
import AuthorizedUserMenu from './AuthorizedUserMenu';
import DocumentsMenu from './DocumentsMenu';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import UnauthorizedUserMenu from './UnauthorizedUserMenu';

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const { instance } = useMsal();
  const headerClasses = useHeaderStyles();

  const [isBasePath, setIsBasePath] = useState(true);

  useEffect(() => {
    // Determine the color based on the current path
    const isBasePath = location.pathname === '/' || location.pathname === '/stocktradeiq.com';
    setIsBasePath(isBasePath);
  }, [location.pathname]); // Re-run this effect whenever the pathname changes

  return (
    <Box
      className="header"
      sx={{ width: '100%', overflow: 'auto', height: header.height, backgroundColor: 'transparent', marginTop: '5px' }}
    >
      <Toolbar variant="dense" classes={{ root: headerClasses.header }}>
        <Box className="navigationBar">
          <AuthenticatedTemplate>
            <div style={{ color: isBasePath && false ? 'white' : 'black' }}>
              <AuthorizedUserMenu />
            </div>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <div style={{ color: isBasePath && false ? 'white' : 'black' }}>
              <UnauthorizedUserMenu
                navigate={navigate}
                instance={instance}
                loginRequest={loginRequest}
                isBasePath={isBasePath}
                DocumentsMenu={DocumentsMenu}
              />
            </div>
          </UnauthenticatedTemplate>
        </Box>
      </Toolbar>
    </Box>
  );
}

export default Header;
