import {
  Box, Dialog, Grid, Paper, Stack, Switch, Typography, styled,
} from '@mui/material';
import React from 'react';
import {
  subscriptionPlans,
  SubscriptionPlanType,
  getSubscriptionPrice,
  getSubscriptionOldPrice
} from '../../models/subscriptionConfig';
import { useSubscriptionCheckout } from '../../hooks/useSubscriptionCheckout';
import FreeResourcesExceededDialogSubscription from './FreeResourceExceededDialogSubscription';

interface ChooseBillingPeriodDialogPropsModel {
  isDialogOpened: boolean;
  subscriptionPlanType: SubscriptionPlanType;
  setIsDialogOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ChooseBillingPeriodDialog({
  isDialogOpened,
  subscriptionPlanType,
  setIsDialogOpened,
}: ChooseBillingPeriodDialogPropsModel) {
  const { buySubscription } = useSubscriptionCheckout();

  function buySubscriptionInternal(planType: SubscriptionPlanType, isAnnual: boolean, isTrial: boolean) {
    buySubscription(planType, isAnnual, isTrial);
    setIsDialogOpened(false);
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '90%',
  }));

  function CornerSVG() {
    return (
      <svg viewBox="0 0 20 20" width="50" height="50">
        <defs>
          <linearGradient id="myGradient" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="#990000" />
            <stop offset="80%" stopColor="#D70000" />
            <stop offset="99%" stopColor="#B50000" />
          </linearGradient>
        </defs>
        <path d="M0 0 L20 0 L20 20 Z" fill="transparent" />
        <path d="M0 0 L20 0 L20 20" fill="url(#myGradient)" />
        <text x="0" y="11" fill="white" fontSize="3px" transform="rotate(45 12 15)">
          POPULAR
        </text>
      </svg>
    );
  }

  return (
    <Dialog
      open={isDialogOpened}
      onClose={() => setIsDialogOpened(false)}
      PaperProps={{
        sx: {
          width: '800px',
          maxWidth: '90vw',
        }
      }}
    >
      <Box sx={{
        width: '100%',
        height: '570px',
        maxWidth: '80vw',
        minWidth: '500px',
      }}>
        <Box sx={{ paddingTop: '30px', paddingBottom: '10px' }}>
          <Typography style={{ fontWeight: '700', fontSize: '20px' }} className="center-items">
            Choose plan that fits your needs:
          </Typography>
        </Box>
        <Grid
          sx={{
            height: '77%',
            paddingTop: '20px',
            paddingLeft: '40px',
            paddingRight: '40px',
          }}
          container
          spacing={5}
        >
          <Grid item xs={6}>
            <Item>
              <FreeResourcesExceededDialogSubscription
                isAnnual={false}
                buySubscription={() => buySubscriptionInternal(subscriptionPlanType, false, true)}
                subscriptionName={`${subscriptionPlans[subscriptionPlanType].displayName} monthly`}
                price={getSubscriptionPrice(subscriptionPlanType, false, false)}
                oldPrice={getSubscriptionOldPrice(subscriptionPlanType, false, false)}
                isPrefered={false}
                subscriptionResources={subscriptionPlans[subscriptionPlanType].resources}
              />
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', top: 0, right: 0 }}>
                <CornerSVG />
              </div>
              <FreeResourcesExceededDialogSubscription
                isAnnual={true}
                buySubscription={() => buySubscriptionInternal(subscriptionPlanType, true, true)}
                subscriptionName={`${subscriptionPlans[subscriptionPlanType].displayName} annually`}
                price={getSubscriptionPrice(subscriptionPlanType, true, false)}
                oldPrice={getSubscriptionOldPrice(subscriptionPlanType, true, false)}
                isPrefered
                subscriptionResources={subscriptionPlans[subscriptionPlanType].resources}
              />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}