import { useQuery } from '@tanstack/react-query';
import CompanyDetailedDataService from '../services/companyDetailedDataService';
import { useState } from 'react';

export default function useStockKeyTakeaways(symbol: string) {
  const [isForbidden, setIsForbidden] = useState(false); // Add this state


  const query = useQuery([symbol, 'getStockKeyTakeaways'],
    () => CompanyDetailedDataService.getStockKeyTakeaways(symbol),
    {
      retry: (failureCount, error: any) => {
        // Don't retry on 403 errors
        if (error?.response?.status === 403) {
          return false;
        }
        // Default retry behavior for other errors (3 times)
        return failureCount < 3;
      },
      onError: ((error: any) => {
        if (error?.response?.status == 403) {
          setIsForbidden(true);
        }
      }),
    });

  return { ...query, isForbidden}
}
