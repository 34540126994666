import { Box, Button, Grid, Paper, Stack, TextField, Typography } from '@mui/material';
import useCompanyProfile from '../../../../../../../hooks/useCompanyProfile';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationsIcon from '@mui/icons-material/Notifications';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import './ActionPanel.css';
import { useEffect, useState } from 'react';
import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import StockService from '../../../../../../../services/stockService';
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from 'notistack';
import SnackbarUndoButton from '../../../../../../../components/Snackbar/SnackbarUndoButton';
import { useFreeResourcesExceededDialogContext } from '../../../../../../../contexts/FreeResourcesExceededDialogContext';
import { useDialogContext } from '../../../../../../../contexts/DialogContext';
import useIgnoredStocks from '../../../../../../../hooks/useIgnoredStocks';
import AddToWatchlistDialog from '../../../../../../../components/analyticsGrid/Dialog/AddToWatchlistDialog/AddToWatchlistDialog';
import usePortfolioValueByCompanyByPortfolioIds from '../../../../../../../hooks/usePortfoliosValueByCompany';
import useWatchlistNames from '../../../../../../../hooks/useWatchlistNames';
import AddToPortfolioDialog from '../../../../../../../components/analyticsGrid/Dialog/AddToPortfolioDialog/AddToPortfolioDialog';
import usePortfolioNames from '../../../../../../../hooks/usePortfolioNames';
import useSymbolNote from '../../../../../../../hooks/useSymbolNote';
import NoteService from '../../../../../../../services/symbolNoteService';
import AddPriceAlertDialog from '../../../../../../../components/analyticsGrid/Dialog/AddAlertDialog/AddPriceAlertDialog';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { loginRequest } from '../../../../../../../authConfig';
import AddFundamentalAlertDialog from '../../../../../../../components/analyticsGrid/Dialog/AddAlertDialog/AddFundamentalAlertDialog';

interface ActionPanelPropsType {
    stockSymbol: string | undefined,
}

export default function ActionPanel({ stockSymbol }: ActionPanelPropsType) {
    const {
        data: companyProfile,
        isLoading: isLoadingCompanyProfile,
    } = useCompanyProfile(stockSymbol as string);
    const { data: ignoredStocks } = useIgnoredStocks();
    const { data: portfolioNames, dataUpdatedAt: portfolioDataUpdatedAt } = usePortfolioNames();
    const { data: watchlists, dataUpdatedAt: watchlistsDataUpdatedAt } = useWatchlistNames();
    const { data: symbolNoteQuery, dataUpdatedAt: symbolNoteQueryUpdatedAt } = useSymbolNote(stockSymbol!);
    const [isInIgnored, setIsInIgnored] = useState<boolean | undefined>(undefined);
    const [isInPortfolio, setIsInPortfolio] = useState<boolean | undefined>(undefined);
    const [isInWatchlists, setIsInWatchlists] = useState<boolean | undefined>(undefined);
    const [isWatchlistModalOpen, setIsWatchlistModalOpen] = useState<boolean>(false);
    const [isPriceAlertModalOpen, setIsPriceAlertModalOpen] = useState<boolean>(false);
    const [isFundamentalAlertModalOpen, setIsFundamentalAlertModalOpen] = useState<boolean>(false);
    const [isPortfolioModalOpen, setIsPortfolioModalOpen] = useState<boolean>(false);
    const isAuthenticated = useIsAuthenticated();
    const freeResourcesExceededDialogContext = useFreeResourcesExceededDialogContext();
    const postNote = useMutation(() => NoteService.postSymbolNote(stockSymbol!, formik.values.note), {
        onError: (() => {
            enqueueSnackbar('Entered data is incorrect', { variant: 'error' });
        }),
        onSuccess: (() => {
            enqueueSnackbar('The note has been saved succesfully');
        }),
    });
    const notLoggedUserDialog = useDialogContext();
    const QueryClient = useQueryClient();
    const { instance } = useMsal();

    useEffect(() => {
        if (ignoredStocks && stockSymbol) {
            const isIgnored = ignoredStocks.includes(stockSymbol);
            setIsInIgnored(isIgnored);
        }
    }, [ignoredStocks, stockSymbol]);


    useEffect(() => {
        if (watchlists && watchlists.length > 0)
            setIsInWatchlists(watchlists.some((w: { symbols: string | string[]; }) => w.symbols.includes(stockSymbol!)));
    }, [watchlistsDataUpdatedAt]);

    useEffect(() => {
        if (symbolNoteQuery) {
            formik.setFieldValue('note', symbolNoteQuery);
        }
    }, [symbolNoteQueryUpdatedAt, stockSymbol]);

    useEffect(() => {
        setIsInPortfolio(portfolioNames?.some((p: any) => p.symbolValues.some((s: any) => s.symbol == stockSymbol)));
    }, [portfolioDataUpdatedAt]);

    const putNewIgnoredStock = useMutation((symbolToPut: string) => (
        StockService.putNewIgnoredStock(symbolToPut)), {
        onError: ((error: any) => {
            if (error?.response?.status == 403) {
                freeResourcesExceededDialogContext.setDialogText('You need to buy paid account.');
                freeResourcesExceededDialogContext.setIsDialogOpened(true);
            }
            else {
                enqueueSnackbar('Adding to ignored failed, try again.', { variant: 'error' });
            }
        }),
        onSuccess: (data, variables) => {
            setIsInIgnored(true);

            QueryClient.setQueryData(
                ['getIgnoredStocks'],
                (oldModel: any) => [...oldModel, stockSymbol],
            );

            enqueueSnackbar(`${variables} stock succesfully added to ignored`, {
                action: (snackbarId: SnackbarKey) => (
                    <SnackbarUndoButton undoClick={() => {
                        // eslint-disable-next-line no-use-before-define
                        deleteIgnoredStock.mutate(variables);
                        // eslint-disable-next-line no-use-before-define
                        closeSnackbar(snackbarId);
                    }}
                    />
                ),
            });
        },
    });

    const formik = useFormik({
        initialValues: {
            note: ''
        },
        validationSchema: Yup.object({
            note: Yup.string()
                .max(500, 'Max note length is 500 characters')
                .required('Required')
        }),
        onSubmit: (values) => {
            handleSaveNoteClick();
        },
        enableReinitialize: true,
    });

    const deleteIgnoredStock = useMutation((symbolToDelete: string) => (
        StockService.deleteIgnoredStock(symbolToDelete)), {
        onSuccess: (data, variables) => {
            setIsInIgnored(false);

            QueryClient.setQueryData(
                ['getIgnoredStocks'],
                (oldModel: any) => oldModel.filter((item: string) => item !== stockSymbol),
            );
            enqueueSnackbar(`${variables} stock succesfully removed from ignored`, {
                action: (snackbarId: SnackbarKey) => (
                    <SnackbarUndoButton undoClick={() => {
                        putNewIgnoredStock.mutate(variables);
                        closeSnackbar(snackbarId);
                    }}
                    />
                ),
            });
        },
    });

    function handleIgnoreClick() {
        if (isAuthenticated) {
            if (isInIgnored) {
                deleteIgnoredStock.mutate(stockSymbol!);
            } else {
                putNewIgnoredStock.mutate(stockSymbol!);
            }
        } else {
            instance.loginRedirect(loginRequest);
        }
    }

    function handleWatchlistClick() {
        if (isAuthenticated) {
            setIsWatchlistModalOpen(true);
        } else {
            instance.loginRedirect(loginRequest);
        }
    }

    function handlePriceAlertClick() {
        if (isAuthenticated) {
            setIsPriceAlertModalOpen(true);
        } else {
            instance.loginRedirect(loginRequest);
        }
    }

    function handleFundamentalAlertClick() {
        if (isAuthenticated) {
            setIsFundamentalAlertModalOpen(true);
        } else {
            instance.loginRedirect(loginRequest);
        }
    }

    function handlePortfolioClick() {
        if (isAuthenticated) {
            setIsPortfolioModalOpen(true);
        } else {
            instance.loginRedirect(loginRequest);
        }
    }

    function handleSaveNoteClick() {
        if (isAuthenticated) {
            postNote.mutate();
        } else {
            instance.loginRedirect(loginRequest);
        }
    }

    return (
        <Paper sx={{ width: '100%', height: '100%' }} elevation={7}>
            <Box sx={{ width: '100%', background: '#FFFFFF' }}>
                <Typography variant="h6" sx={{ padding: '20px 0px', width: '100%', textAlign: 'center', fontSize: '16px', fontWeight: '700' }}>
                    ACTION PANEL
                </Typography>
                <Grid container>
                    <Grid item xs={6} sx={{ marginTop: '30px' }}>
                        <Grid container spacing={2} sx={{ width: '96%', marginLeft: '1%' }}>
                            <Grid item xs={12}>
                                <Button className='action-panel-button' variant="outlined" startIcon={<DeleteIcon sx={{ color: isInIgnored ? 'red' : 'inherit' }} />} fullWidth onClick={() => handleIgnoreClick()} > IGNORE</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button className='action-panel-button' variant="outlined" startIcon={<NotificationsIcon />} fullWidth onClick={() => handlePriceAlertClick()}>SET PRICE ALERT</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button className='action-panel-button' variant="outlined" startIcon={<NotificationsIcon />} fullWidth onClick={() => handleFundamentalAlertClick()}>FUNDAMENTAL ALERT</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button className='action-panel-button' variant="outlined" startIcon={<VisibilityOutlinedIcon sx={{ color: isInWatchlists ? '#FFB633' : 'inherit' }} />} fullWidth onClick={() => handleWatchlistClick()}>ADD TO WATCHLIST</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button className='action-panel-button' variant="outlined" startIcon={<PersonOutlineOutlinedIcon sx={{ color: isInPortfolio ? '#FFB633' : 'inherit' }} />} fullWidth onClick={() => handlePortfolioClick()}>ADD TO PORTFOLIO</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ marginLeft: '10%', marginRight: '10%' }}>
                            <form onSubmit={formik.handleSubmit}>
                                <Typography variant="h6" sx={{ padding: '5px 0px', width: '100%', textAlign: 'left', fontSize: '14px', fontWeight: '700' }}>
                                    CREATE A NOTE
                                </Typography>
                                <TextField multiline rows={8} sx={{ width: '100%', height: '100%' }}
                                    id="note"
                                    name="note"
                                    value={formik.values.note}
                                    onChange={formik.handleChange}
                                    error={formik.touched.note && Boolean(formik.errors.note)}
                                    helperText={formik.touched.note && formik.errors.note} />
                                <Box>
                                    <Button className='action-panel-button' sx={{ margin: '5%', width: '90%' }} variant="outlined" fullWidth type="submit">SAVE NOTE</Button>
                                </Box>
                            </form>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <AddPriceAlertDialog isOpen={isPriceAlertModalOpen} closePopup={() => setIsPriceAlertModalOpen(false)} symbol={stockSymbol!} price={companyProfile?.price || 0} name={companyProfile?.companyName!} />
            <AddFundamentalAlertDialog isOpen={isFundamentalAlertModalOpen} closePopup={() => setIsFundamentalAlertModalOpen(false)} symbol={stockSymbol!} name={companyProfile?.companyName!} />
            <AddToWatchlistDialog isOpen={isWatchlistModalOpen} closePopup={() => setIsWatchlistModalOpen(false)} symbol={stockSymbol!} />
            <AddToPortfolioDialog isOpen={isPortfolioModalOpen} closePopup={() => setIsPortfolioModalOpen(false)} symbol={stockSymbol!} />
        </Paper >
    );
}
