import {
  Box,
  Button,
  keyframes,
  Stack,
  Typography
} from '@mui/material';
import DashboardTop from './components/DashboardTop';
import FunctionsWithCarousel from './components/FunctionsWithCarousel';
import DedicatedFunctionsForEffectiveWork from './components/DedicatedFunctionsForEffectiveWork';
import ForWho from './components/ForWho';
import ComparePlans from './components/ComparePlans';
import FAQ from './components/FAQ';
import { loginRequest } from '../../authConfig';
import { UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import ChooseBillingPeriodDialog from '../../layout/components/ChooseBillingPeriodDialog';
import { useState } from 'react';

export default function Dashboard() {
  const { instance } = useMsal();

  return (
    <Box style={{ width: '100%' }}>
      <DashboardTop />
      <Box style={{
        width: '100%',
        minHeight: '15vh',
        background: 'white',
        borderRadius: '0 0 30px 30px'
      }} />
      <FunctionsWithCarousel />
      <DedicatedFunctionsForEffectiveWork />
      <ForWho />
      <ComparePlans />
      <FAQ />
      <Box
        sx={{
          width: '100%',
          background: 'white',
          display: 'flex',
          flexDirection: 'column', // Stack button and typography row vertically
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '50px',
          paddingBottom: '150px'
        }}
      >
        <UnauthenticatedTemplate>
          <Button
            style={{
              padding: '12px 50px',
              margin: '10px',
              textTransform: 'none',
              borderRadius: '10px',  // Rounded edges
              background: '#30CFD0',  // Gradient background
              color: 'white',
              fontSize: '20px',
              boxShadow: '2px 8px 12.4px 1px #00000021'
            }}
            variant="contained"
            size="large"
            onClick={() => instance.loginRedirect(loginRequest)}
            aria-label="I'm starting for free button"
          >
            I'm starting for free
          </Button>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '16px', // Space between typographies
              marginTop: '8px'
            }}
          >
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: '600'
              }}
            >
              No card needed
            </Typography>
          </Box>

        </UnauthenticatedTemplate>

      </Box>
    </Box>
  );
}
