import { Grid } from '@mui/material';
import CompanyAboutTable from './components/CompanyAboutTable';
import DividendTable from './components/DividendTable';
import FundamentalFirstTable from './components/FundamentalFirstTable';
import FundamentalPerShareTable from './components/FundamentalPerShareTable';
import FundamentalSecondTable from './components/FundamentalSecondTable';
import FundamentalThirdTable from './components/FundamentalThirdTable';
import TechnicalTable from './components/TechnicalTable';
import './Tables.css';

interface TablesPropsType {
  stockSymbol: string | undefined,
  tablesWidth: string,
  paperHeight: string
}

export default function Tables({ stockSymbol, tablesWidth = '360px', paperHeight = '260px' }: TablesPropsType) {
  return (
    <Grid container spacing={10} alignItems={'center'} alignContent={'center'}>
      <Grid item xs={12} md={6} xl={4}>
        <FundamentalFirstTable
          stockSymbol={stockSymbol}
          width={tablesWidth}
          paperHeight={paperHeight}
        />
      </Grid>
      <Grid item xs={12} md={6} xl={4}>
        <FundamentalSecondTable
          stockSymbol={stockSymbol}
          width={tablesWidth}
          paperHeight={paperHeight}
        />
      </Grid>
      <Grid item xs={12} md={6} xl={4}>
        <FundamentalThirdTable
          stockSymbol={stockSymbol}
          width={tablesWidth}
          paperHeight={paperHeight}
        />
      </Grid>
      <Grid item xs={12} md={6} xl={4}>
        <FundamentalPerShareTable
          stockSymbol={stockSymbol}
          width={tablesWidth}
          paperHeight={paperHeight}
        />
      </Grid>
      <Grid item xs={12} md={6} xl={4}>
        <TechnicalTable
          stockSymbol={stockSymbol as string}
          width={tablesWidth}
          paperHeight={paperHeight}
        />
      </Grid>
      <Grid item xs={12} md={6} xl={4}>
        <DividendTable
          stockSymbol={stockSymbol}
          width={tablesWidth}
          paperHeight={paperHeight}
        />
      </Grid>
    </Grid>
  );
}
