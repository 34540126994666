/* eslint-disable global-require */
import { Box, Grid, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import './Indicators.css';
import InfoIcon from '@mui/icons-material/Info';
import GoodIndicator from '../../../assets/indicators/good.png';
import BadIndicator from '../../../assets/indicators/bad.png';
import Indicator from '../../../models/dtos/indicator';
import useKeyTakeawaysStyles from '../useKeyTakeawaysStyles';
import BasicTable from '../../../pages/stockDetailedData/components/BasicTable/BasicTable';
import { ReactNode } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


interface IndicatorsPropsModel {
  indicators: Indicator[]
}

export default function Indicators({ indicators }: IndicatorsPropsModel) {

  const sortedIndicators = indicators.slice().sort((a, b) => (a.favorable === b.favorable) ? 0 : a.favorable ? -1 : 1);


  return (
    <Box sx={{
      display: 'flex', justifyContent: 'center', flexDirection: 'column', height: '100%', width: '100%', padding: '4% 4% 4% 0', alignItems: 'flex-start',
    }}
    >
      <Paper sx={{ height: '100%', width: '100%', padding: '4%' }} elevation={4}>
        <Grid container sx={{ width: '100%', height: '100%', paddingLeft: '10px', paddingTop: '10px' }}>
          {sortedIndicators && sortedIndicators.map(i => (
            <Grid item xs={6} sx={{ minHeight: '44px', width: '100%' }} key={i.label}>
              <Stack direction={'row'}>
                <Typography sx={{ fontSize: '18px', fontWeight: '700' }} align='left'>
                  {i.label}
                </Typography>
                <Box sx={{ marginLeft: 'auto', paddingRight: '20px' }}>
                  {i.favorable ?
                    <>
                      <img
                        src={GoodIndicator}
                        alt={"Good Indicator"}
                        style={{ width: '15px', height: '15px' }}
                      />
                    </> :
                    <>
                      <img
                        src={BadIndicator}
                        alt={"Bad Indicator"}
                        style={{ width: '22px', height: '22px' }}
                      />
                    </>}
                </Box>
              </Stack>
              <Typography sx={{ fontSize: '12px', fontWeight: '700' }} align='left'>
                {i.description}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  );
}
