/* eslint-disable react/jsx-props-no-spreading */
import { Box, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { NavigateNext, NavigateBefore } from '@mui/icons-material';
import { useDraggable } from 'react-use-draggable-scroll';
import './Swiper.css';

interface SwiperPropsModel {
  swiperHeight: string;
  horizontalScrollDistanceOnClick?: number;
}

export default function Swiper({
  swiperHeight,
  horizontalScrollDistanceOnClick = 280,
  children,
}: React.PropsWithChildren<SwiperPropsModel>) {
  const swiperRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
  const [showRightArrow, setShowRightArrow] = useState<boolean>(false);
  const { events } = useDraggable(swiperRef);
  const theme = useTheme();

  function scrollSwiperHorizontal(scrollDistance: number) {
    swiperRef.current.scrollLeft += scrollDistance;
  }

  function onSwiperScroll() {
    const { scrollLeft } = swiperRef.current;
    const { clientWidth } = swiperRef.current;
    const { scrollWidth } = swiperRef.current;
    setShowLeftArrow(scrollLeft !== 0);
    setShowRightArrow(scrollLeft + clientWidth !== scrollWidth);
  }

  function getGradient(direction: string, color: string) {
    return `linear-gradient(to ${direction}, ${color} 0%, transparent 100%)`;
  }

  useEffect(() => {
    onSwiperScroll();
  }, [children]);

  return (
    <Box sx={{ position: 'relative', width: '100%', height: swiperHeight }}>
      <Box
        ref={swiperRef}
        {...events}
        onScroll={onSwiperScroll}
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          overflowX: 'auto',
          overflowY: 'hidden',
          scrollBehavior: 'smooth',
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
          position: 'relative',
          gap: '16px',
          padding: '0 8px'
        }}
      >
        {children}
      </Box>
      
      {showLeftArrow && (
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
            width: '48px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: getGradient('right', theme.palette.background.paper),
            cursor: 'pointer',
            zIndex: 1
          }}
          onClick={() => scrollSwiperHorizontal(-horizontalScrollDistanceOnClick)}
        >
          <NavigateBefore fontSize="large" />
        </Box>
      )}
      
      {showRightArrow && (
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            height: '100%',
            width: '48px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: getGradient('left', theme.palette.background.paper),
            cursor: 'pointer',
            zIndex: 1
          }}
          onClick={() => scrollSwiperHorizontal(horizontalScrollDistanceOnClick)}
        >
          <NavigateNext fontSize="large" />
        </Box>
      )}
    </Box>
  );
}