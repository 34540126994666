import { Box, Paper, Skeleton, Typography } from '@mui/material';

interface CompanyDescriptionPropsModel {
  description: string | undefined;
}

export default function CompanyDescription({ description }: CompanyDescriptionPropsModel) {
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h6" sx={{ padding: '15px 50px 5px 15px', width: '100%', textAlign: 'left', fontSize: '20px', fontWeight: '700' }}>
        DESCRIPTION
      </Typography>
      <Paper
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          width: '100%',
          padding: '20px'
        }} elevation={7}
      >
        {description
          ? (
            <Typography sx={{ width: '96%', margin: 'auto' }} variant="body1">
              {description}
            </Typography>
          )
          : <Skeleton variant="rectangular" width="80%" height="300px" />}
      </Paper>
    </Box>
  );
}
