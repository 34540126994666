import React, { useEffect } from 'react';

declare global {
    interface Window {
      dataPopupClosed?: (data: any) => void;
    }
  }

interface FastSpringProviderProps {
  onPopupClosed: (data: any) => void;
}

const FastSpringProvider: React.FC<FastSpringProviderProps> = ({ onPopupClosed }) => {
  useEffect(() => {
    // Define constants
    const STOREFRONT = "stocktradeiq.onfastspring.com/popup-stocktradeiq";
    const SCRIPT_VERSION = "1.0.0";

    // Define the callback function in the window scope
    window.dataPopupClosed = (data) => {
      if (onPopupClosed) {
        onPopupClosed(data);
      }
    };

    // Create and append the FastSpring script
    const script = document.createElement('script');
    script.id = 'fsc-api';
    script.src = `https://sbl.onfastspring.com/sbl/${SCRIPT_VERSION}/fastspring-builder.min.js`;
    script.type = 'text/javascript';
    script.setAttribute('data-storefront', STOREFRONT);
    script.setAttribute('data-popup-closed', 'dataPopupClosed');
    script.async = true;

    document.body.appendChild(script);

    // Clean up when component unmounts
    return () => {
      delete window.dataPopupClosed;
      const existingScript = document.getElementById('fsc-api');
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, [onPopupClosed]);

  return null;
};

export default FastSpringProvider;