import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormLabel, Grid, Skeleton, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { Operator } from "./operatorEnum";
import { useEffect, useState } from "react";
import './alertDialog.css';
import AlertService from "../../../../services/alertService";
import { useMutation } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { Parameter } from "./parameterEnum";
import { useFreeResourcesExceededDialogContext } from "../../../../contexts/FreeResourcesExceededDialogContext";

interface Props {
  symbol: string,
  name: string,
  isOpen: boolean,
  closePopup: () => void
}

export default function AddFundamentalAlertDialog({ symbol, name, isOpen, closePopup }: Props) {
  const [logoLoaded, setLogoLoaded] = useState<boolean>(false);
  const freeResourcesExceededDialogContext = useFreeResourcesExceededDialogContext();

  const expirationDate = new Date();
  expirationDate.setMonth(expirationDate.getMonth() + 1);
  const formattedDate = expirationDate.toLocaleDateString('en-CA', { // Use 'en-CA' for YYYY-MM-DD format
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });


  const postNewAlert = useMutation((data: Object) => (
    AlertService.postNewAlert(data)), {
    retry: (failureCount, error: any) => {
      return false;
    },
    onMutate: ((variables: any) => {
      closePopup()
    }),
    onError: ((error: any) => {
      if (error?.response?.status == 403) {
        freeResourcesExceededDialogContext.setDialogText('ALERTS');
        freeResourcesExceededDialogContext.setIsDialogOpened(true);
      }
      else {
        enqueueSnackbar('Alert creation failed', { variant: 'error' });
      }
    }),
    onSuccess: ((data: { data: any; }, variables: any, context: any) => {
      enqueueSnackbar('New alert has been created');
    }),
  });

  const formik = useFormik({
    initialValues: {
      operator: Operator.CrossingUp.Value,
      threshold: 0,
      name: '',
      expiration: formattedDate,
      symbol: symbol,
      parameter: Parameter.PE.Value,
      id: -1,
    },
    validationSchema: Yup.object({
      operator: Yup.mixed()
        .required('Required'),
      parameter: Yup.mixed()
        .required('Required'),
      threshold: Yup.number()
        .required('Required'),
      name: Yup.string()
        .required('Required'),
    }),
    onSubmit: (values) => {
      postNewAlert.mutate(values);
    },
    enableReinitialize: true,
  });

  return (
    <Dialog
      open={isOpen}
      onClose={closePopup}
      PaperProps={{
        onSubmit: (event: any) => {
          event.preventDefault();
          closePopup();
        },
      }}
    >
      <DialogTitle align="center" sx={{ fontSize: '32px', fontWeight: '600' }}>Create fundamental alert</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Stack direction={"row"} sx={{ marginLeft: '80px', marginRight: '80px' }}>
            <Box>
              <img
                src={`https://financialmodelingprep.com/image-stock/${symbol}.png`}
                alt="logo"
                height="60px"
                style={logoLoaded ? {} : { display: 'none' }}
                onLoad={() => setLogoLoaded(true)}
              />
              <Skeleton variant="circular" width={50} height={50} style={logoLoaded ? { display: 'none' } : {}} />
            </Box>
            <Stack direction={"column"} sx={{ marginLeft: '40px' }}>
              <Typography sx={{ overflow: 'hidden', fontSize: '18px', fontWeight: 600, marginTop: '10px', color: 'black' }} variant="inherit">
                {name} ({symbol})
              </Typography>
            </Stack>
          </Stack>
        </DialogContentText>
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12}>
              <FormControl sx={{ width: '90%', marginTop: '30px' }} variant='standard'>
                <FormLabel>Alert name</FormLabel>
                <TextField
                  id="name"
                  name="name"
                  type="string"
                  variant='standard'
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: '90%', marginTop: '30px' }} variant='standard'>
                <FormLabel>Parameter</FormLabel>
                <TextField
                  id="parameter"
                  name="parameter"
                  select
                  value={formik.values.parameter}
                  onChange={formik.handleChange}
                  variant='standard'
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value={Parameter.PE.Value}>{Parameter.PE.Description}</option>
                  <option value={Parameter.PS.Value}>{Parameter.PS.Description}</option>
                  <option value={Parameter.PB.Value}>{Parameter.PB.Description}</option>
                  <option value={Parameter.DebtEquity.Value}>{Parameter.DebtEquity.Description}</option>
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: '90%', marginTop: '30px' }} variant='standard'>
                <FormLabel>Expiration date</FormLabel>
                <TextField
                  id="expirationDate"
                  name="expirationDate"
                  type="date"
                  variant='standard'
                  value={formik.values.expiration}
                  onChange={formik.handleChange}
                  error={formik.touched.expiration && Boolean(formik.errors.expiration)}
                  helperText={formik.touched.expiration && formik.errors.expiration}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: '90%', marginTop: '30px' }} variant='standard'>
                <FormLabel>Threshold</FormLabel>
                <TextField
                  id="threshold"
                  name="threshold"
                  type="number"
                  variant='standard'
                  value={formik.values.threshold}
                  onChange={formik.handleChange}
                  error={formik.touched.threshold && Boolean(formik.errors.threshold)}
                  helperText={formik.touched.threshold && formik.errors.threshold}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: '90%', marginTop: '30px' }} variant='standard'>
                <FormLabel>Operator</FormLabel>
                <TextField
                  id="operator"
                  name="operator"
                  select
                  value={formik.values.operator}
                  onChange={formik.handleChange}
                  variant='standard'
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value={Operator.CrossingDown.Value}>{Operator.CrossingDown.Description}</option>
                  <option value={Operator.CrossingUp.Value}>{Operator.CrossingUp.Description}</option>
                </TextField>
              </FormControl>
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={closePopup}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}