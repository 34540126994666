import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import StockAdvancedKeyTakeaways from '../../../../components/KeyTakeaways/StockAdvancedKeyTakeaways/StockAdvancedKeyTakeaways';
import StockUnauthorizedAdvancedKeyTakeaways from '../../../../components/KeyTakeaways/StockAdvancedKeyTakeaways/StockUnauthorizedAdvancedKeyTakeaways';
import useCompanyProfile from '../../../../hooks/useCompanyProfile';
import CompanyOverviewChart from './components/CompanyOverviewChart';
import Tables from './components/Tables/Tables';
import GeneralTable from './components/Tables/components/GeneralTable';
import ActionPanel from './components/Tables/components/ActionPanel';
import SimilarCompanies from './components/SimilarCompanies/SimilarCompanies';
import CompanyDescription from './components/CompanyDescription';
import StockNews from '../../../news/components/StockNews';
import CompanyNews from '../CompanyNews/CompanyNews';

interface OverviewPropModel {
  stockSymbol: string;
}

export default function Overview(overviewProp: OverviewPropModel) {
  const { stockSymbol } = overviewProp;
  const tablesWidth = '100%';
  const paperHeight = '250px';
  const doubledTablesWidth = '100%';
  const halfTablesWidth = '100%';
  const { data: companyProfile } = useCompanyProfile(stockSymbol);

  return (
    <Box >
      <Grid sx={{ width: 'min(95%, 1700px)', margin: 'auto' }} container spacing={6} alignItems={'stretch'}>
        <Grid item xs={12}>
          <Grid container spacing={4} alignItems={'stretch'}>
            <Grid item xs={12} lg={8}>
              <Paper style={{ height: '100%' }} elevation={7}>
                <CompanyOverviewChart
                  symbol={stockSymbol}
                  currency={companyProfile?.currency} showDatePicker={true} height={350} full/>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={4} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
              <ActionPanel stockSymbol={stockSymbol} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <GeneralTable stockSymbol={stockSymbol} paperHeight={'350px'} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box sx={{ height: '100%' }}>
                <AuthenticatedTemplate>
                  <StockAdvancedKeyTakeaways paperHeight={'350px'} symbol={stockSymbol as string} />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <StockUnauthorizedAdvancedKeyTakeaways />
                </UnauthenticatedTemplate>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={12}>
          <Tables stockSymbol={stockSymbol} tablesWidth={tablesWidth} paperHeight={paperHeight} />
        </Grid>
        <Grid item lg={12}>
          <SimilarCompanies stockSymbol={stockSymbol} />
        </Grid>
        <Grid item lg={12}>
          <CompanyDescription description={companyProfile?.description} />
        </Grid>
        <Grid item lg={12}>
          <Box>
            <Typography variant="h6" sx={{ padding: '15px 50px 5px 15px', width: '100%', textAlign: 'center', fontSize: '28px', fontWeight: '700' }}>
              NEWS
            </Typography>
            <CompanyNews symbol={stockSymbol} limit={16} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
