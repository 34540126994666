import {
  Box, Dialog, Grid, Paper, Stack, Switch, Typography, styled,
} from '@mui/material';
import React from 'react';
import { 
  subscriptionPlans,
  SubscriptionPlanType,
  getSubscriptionPrice, 
  getSubscriptionOldPrice 
} from '../../models/subscriptionConfig';
import { useSubscriptionCheckout } from '../../hooks/useSubscriptionCheckout';
import FreeResourcesExceededDialogSubscription from './FreeResourceExceededDialogSubscription';

interface FreeResourcesExceededDialogPropsModel {
  isDialogOpened: boolean;
  setIsDialogOpened: React.Dispatch<React.SetStateAction<boolean>>;
  dialogText: string;
}

export default function FreeResourcesExceededDialog({
  isDialogOpened,
  setIsDialogOpened,
  dialogText,
}: FreeResourcesExceededDialogPropsModel) {
  const [isAnnual, setIsAnnual] = React.useState(false);
  const { buySubscription } = useSubscriptionCheckout();

  const handleIsAnnualChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAnnual(event.target.checked);
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '90%',
  }));

  function CornerSVG() {
    return (
      <svg viewBox="0 0 20 20" width="50" height="50">
        <defs>
          <linearGradient id="myGradient" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="#990000" />
            <stop offset="80%" stopColor="#D70000" />
            <stop offset="99%" stopColor="#B50000" />
          </linearGradient>
        </defs>
        <path d="M0 0 L20 0 L20 20 Z" fill="transparent" />
        <path d="M0 0 L20 0 L20 20" fill="url(#myGradient)" />
        <text x="0" y="11" fill="white" fontSize="3px" transform="rotate(45 12 15)">
          POPULAR
        </text>
      </svg>
    );
  }

  const handleBuySubscription = (planType: SubscriptionPlanType, isTrial: boolean) => {
    buySubscription(planType, isAnnual, isTrial);
  };

  return (
    <Dialog 
      open={isDialogOpened} 
      onClose={() => setIsDialogOpened(false)}
      PaperProps={{
        sx: {
          width: '800px',
          maxWidth: '90vw',
        }
      }}
    >
      <Box sx={{
        width: '100%',
        height: '700px',
        maxWidth: '80vw',
        minWidth: '500px',
      }}>
        <Box sx={{ paddingTop: '30px', paddingBottom: '10px' }}>
          <Typography variant="h5" className="center-items" style={{ fontWeight: '600', fontSize: '24px' }}>
            YOU HAVE EXCEEDED FREE LIMIT OF
          </Typography>
          <Typography variant="h5" style={{ color: '#9E00FF', fontWeight: '600', fontSize: '24px' }} className="center-items">
            {dialogText}
          </Typography>
          <Typography variant="h5" style={{ marginTop: '10px', fontWeight: '700', fontSize: '15px' }} className="center-items">
            Choose plan that fits your needs:
          </Typography>
        </Box>
        <Grid
          sx={{
            height: '77%',
            paddingTop: '20px',
            paddingLeft: '40px',
            paddingRight: '40px',
          }}
          container
          spacing={5}
        >
          <Grid item xs={6}>
            <Item>
              <FreeResourcesExceededDialogSubscription
                isAnnual={isAnnual}
                buySubscription={(isTrial) => handleBuySubscription(SubscriptionPlanType.PREMIUM, isTrial)}
                subscriptionName={subscriptionPlans[SubscriptionPlanType.PREMIUM].displayName}
                price={getSubscriptionPrice(SubscriptionPlanType.PREMIUM, isAnnual, false)}
                oldPrice={getSubscriptionOldPrice(SubscriptionPlanType.PREMIUM, isAnnual, false)}
                isPrefered={false}
                subscriptionResources={subscriptionPlans[SubscriptionPlanType.PREMIUM].resources}
              />
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', top: 0, right: 0 }}>
                <CornerSVG />
              </div>
              <FreeResourcesExceededDialogSubscription
                isAnnual={isAnnual}
                buySubscription={(isTrial) => handleBuySubscription(SubscriptionPlanType.DIAMOND, isTrial)}
                subscriptionName={subscriptionPlans[SubscriptionPlanType.DIAMOND].displayName}
                price={getSubscriptionPrice(SubscriptionPlanType.DIAMOND, isAnnual, false)}
                oldPrice={getSubscriptionOldPrice(SubscriptionPlanType.DIAMOND, isAnnual, false)}
                isPrefered
                subscriptionResources={subscriptionPlans[SubscriptionPlanType.DIAMOND].resources}
              />
            </Item>
          </Grid>
        </Grid>
        <Box>
          <Stack direction="row" component="label" alignItems="center" justifyContent="center">
            <Typography style={{ fontWeight: '600' }}>Monthly</Typography>
            <Switch color='secondary' checked={isAnnual} onChange={handleIsAnnualChange} />
            <Typography style={{ fontWeight: '600', marginRight: '4px' }}>Annually:</Typography>
            <Typography style={{ marginRight: '4px' }}>Additional</Typography>
            <Typography style={{ fontWeight: '600', marginRight: '4px' }}>20%</Typography>
            <Typography>discount</Typography>
          </Stack>
        </Box>
      </Box>
    </Dialog>
  );
}