import { keyframes } from "@emotion/react";
import { KeyboardDoubleArrowRight } from "@mui/icons-material";
import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from 'swiper/modules';
import 'swiper/css';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import DoubleArrowsIcon from '../../../assets/doubleArrows.png';
import ProfileImage from '../../../assets/dashboard/profile.png';
import KeyTakeawaysImage from '../../../assets/dashboard/keyTakeaways.png';
import ScreenerImage from '../../../assets/dashboard/screener.png';
import StatisticsImage from '../../../assets/dashboard/statistics.png';

export default function FunctionsWithCarousel() {
    const cloudMove = keyframes`
      0% {
        transform: translate(0, 0);
      }
      50% {
        transform: translate(-10%, 0);
      }
      100% {
        transform: translate(0, 0);
      }
    `;
    const { instance } = useMsal();


    return (
        <Box
            sx={{
                width: '100%',
                minHeight: '115vh',
                position: 'relative',
                overflow: 'hidden',
                background: 'white'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    bottom: '30px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 1000
                }}
            >
                <img
                    src={DoubleArrowsIcon}
                    alt="scroll indicator"
                    style={{ width: '35px', height: '35px' }}
                />
            </Box>

            <Box
                sx={{
                    position: 'relative',
                    zIndex: 2,
                    height: '100%',
                    width: '100%',
                }}
            >
                <Grid
                    container
                    spacing={1}
                    sx={{ height: '100%' }}
                >
                    <Grid
                        item
                        xs={12}
                        sx={{
                            position: 'relative',
                            height: '200px', // Add a specific height to enable vertical centering
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                width: '90%',
                                height: '100%',
                                backgroundColor: '#DBE6FA',
                                zIndex: -1,
                                borderRadius: '25px'
                            },
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center', // This centers content vertically
                            width: '80%'
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '64px',
                                fontFamily: 'Calibre, sans-serif',
                                fontWeight: '500',
                            }}
                        >
                            Make intelligent investment decisions with us
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '22px',
                                fontFamily: 'Calibre, sans-serif',
                                fontWeight: '400',
                                marginBottom: '38px'
                            }}
                        >
                            We are the only investment tool you need to make <span style={{ fontWeight: 700 }}>informed</span> decisions
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack direction={'column'} sx={{ paddingLeft: '90px', paddingTop: '50px' }}>
                            <Typography
                                sx={{
                                    fontSize: '32px',
                                    fontFamily: 'Calibre, sans-serif',
                                    fontWeight: '600',
                                    marginBottom: '6%',
                                    paddingLeft: '90px'
                                }}
                            >
                                Searching for investment information<br />has never been so effective:
                            </Typography>
                            <Box sx={{ marginBottom: '6%' }}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Box sx={{ padding: '10px' }}>
                                        <div style={{ transform: 'rotate(270deg)' }}>
                                            <img src={DoubleArrowsIcon} style={{}} />
                                        </div>
                                    </Box>
                                    <Typography sx={{
                                        fontSize: '24px',
                                        fontFamily: 'Calibre, sans-serif',
                                        fontWeight: '600',
                                    }}>Learn more and quicker</Typography>
                                </Stack>
                                <Typography sx={{
                                    fontSize: '20px',
                                    lineHeight: '24px',
                                    fontFamily: 'Calibre, sans-serif',
                                    paddingLeft: '30px'
                                }}>
                                    Use a comprehensive stock screener tools suite with a <span style={{ fontWeight: 600 }}>scoring mechanism</span> and <span style={{ fontWeight: 600 }}>key takeaways</span> to elevate your stock screening experience to a new level.
                                </Typography>
                            </Box>
                            <Box sx={{ marginBottom: '6%' }}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Box sx={{ padding: '10px' }}>
                                        <div style={{ transform: 'rotate(270deg)' }}>
                                            <img src={DoubleArrowsIcon} style={{}} />
                                        </div>
                                    </Box>
                                    <Typography sx={{
                                        fontSize: '24px',
                                        fontFamily: 'Calibre, sans-serif',
                                        fontWeight: '600',
                                    }}>Stay informed</Typography>
                                </Stack>
                                <Typography sx={{
                                    fontSize: '20px',
                                    lineHeight: '24px',
                                    fontFamily: 'Calibre, sans-serif',
                                    fontWeight: '400',
                                    paddingLeft: '30px'
                                }}>
                                    Track the market situation for your stock or investment opportunities with dedicated price and <span style={{ fontWeight: 600 }}>fundamental alerts</span>, multiple <span style={{ fontWeight: 600 }}>watchlists</span>, and <span style={{ fontWeight: 600 }}>portfolios</span> you can manage within the app.
                                </Typography>
                            </Box>
                            <Box sx={{ marginBottom: '5%' }}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Box sx={{ padding: '10px' }}>
                                        <div style={{ transform: 'rotate(270deg)' }}>
                                            <img src={DoubleArrowsIcon} style={{}} />
                                        </div>
                                    </Box>
                                    <Typography sx={{
                                        fontSize: '24px',
                                        fontFamily: 'Calibre, sans-serif',
                                        fontWeight: '600',
                                    }}>Make informed decisions</Typography>
                                </Stack>
                                <Typography sx={{
                                    fontSize: '20px',
                                    lineHeight: '24px',
                                    fontFamily: 'Calibre, sans-serif',
                                    fontWeight: '400',
                                    paddingLeft: '30px'
                                }}>
                                    Let us help you utilize full history of available market data for <span style={{ fontWeight: 600 }}>34000</span> stocks from <span style={{ fontWeight: 600 }}>90</span> stock exchanges worldwide to choose the best market and the best stock {`to\u00A0invest\u00A0in\u00A01`}
                                </Typography>
                            </Box>
                            <Box>
                                <Stack direction={'column'} sx={{ width: '350px' }}>
                                    <Button
                                        style={{
                                            padding: '12px 50px',
                                            margin: '10px',
                                            textTransform: 'none',
                                            borderRadius: '10px',  // Rounded edges
                                            background: '#30CFD0',  // Gradient background
                                            color: 'white',
                                            fontSize: '20px',
                                            boxShadow: '2px 8px 12.4px 1px #00000021'
                                        }}
                                        variant="contained"
                                        size="large"
                                        onClick={() => instance.loginRedirect(loginRequest)}
                                        aria-label="I’m starting for free button"
                                    >
                                        I’m starting for free
                                    </Button>
                                    <Typography sx={{ textAlign: 'center', fontSize: '12px', fontWeight: '600' }}> No card required</Typography>
                                </Stack>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Swiper
                            style={{
                                width: '100%',
                                paddingTop: '150px',
                                paddingRight: '90px'
                            }}
                            modules={[EffectCoverflow]}
                            effect={'coverflow'}
                            grabCursor={true}
                            centeredSlides={true}
                            slidesPerView={3}
                            coverflowEffect={{
                                rotate: 20,
                                stretch: 0,
                                depth: 200,
                                modifier: 1,
                                slideShadows: true,
                            }}
                            pagination={{ clickable: true }}
                            navigation={true}
                            loop={true}
                        >
                            <SwiperSlide>
                                <Box
                                    sx={{
                                        width: '100%',
                                        maxWidth: '600px', // Increased from 450px
                                        minWidth: '400px', // Added minimum width
                                        height: 'auto',
                                        margin: '0 auto',
                                    }}
                                >
                                    <img
                                        src={ProfileImage}
                                        style={{
                                            width: '100%',
                                            aspectRatio: '9/8', // Slightly adjusted ratio for better proportions
                                            objectFit: 'cover',
                                            borderRadius: '10px'
                                        }}
                                    />
                                </Box>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Box
                                    sx={{
                                        width: '100%',
                                        maxWidth: '600px',
                                        minWidth: '400px',
                                        height: 'auto',
                                        margin: '0 auto',
                                    }}
                                >
                                    <img
                                        src={KeyTakeawaysImage}
                                        style={{
                                            width: '100%',
                                            aspectRatio: '9/8',
                                            objectFit: 'cover',
                                            borderRadius: '10px'
                                        }}
                                    />
                                </Box>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Box
                                    sx={{
                                        width: '100%',
                                        maxWidth: '600px',
                                        minWidth: '400px',
                                        height: 'auto',
                                        margin: '0 auto',
                                    }}
                                >
                                    <img
                                        src={ScreenerImage}
                                        style={{
                                            width: '100%',
                                            aspectRatio: '9/8',
                                            objectFit: 'cover',
                                            borderRadius: '10px'
                                        }}
                                    />
                                </Box>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Box
                                    sx={{
                                        width: '100%',
                                        maxWidth: '600px',
                                        minWidth: '400px',
                                        height: 'auto',
                                        margin: '0 auto',
                                    }}
                                >
                                    <img
                                        src={StatisticsImage}
                                        style={{
                                            width: '100%',
                                            aspectRatio: '9/8',
                                            objectFit: 'cover',
                                            borderRadius: '10px'
                                        }}
                                    />
                                </Box>
                            </SwiperSlide>
                        </Swiper>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}