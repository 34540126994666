import { Box, Paper, Typography } from '@mui/material';
import BasicTable from '../../../../../components/BasicTable/BasicTable';
import useCompanyOverview from '../../../../hooks/useCompanyOverview';

interface FundamentalPerSharePropsType{
  stockSymbol: string | undefined,
  width : string
  paperHeight : string
}

export default function FundamentalPerShareTable({ stockSymbol, width, paperHeight }: FundamentalPerSharePropsType) {
  const {
    data: companyOverview,
    isLoading: isLoadingCompanyOverview,
  } = useCompanyOverview(stockSymbol as string);

  return (
    <Box sx={{ width, maxWidth: '100%' }}>
      <Typography variant="h6" sx={{ padding: '5px 0px 5px 15px', width: '100%', textAlign: 'left', fontSize: '18px', fontWeight: '700' }}>
        FUNDAMENTALS PER SHARE
      </Typography>
      <Paper sx={{ width, maxWidth: '100%', height: paperHeight }} elevation={7}>
        <BasicTable
          data={{
            'FCF per share': companyOverview?.freeCashFlowPerShareTTM,
            'Revenue per share': companyOverview?.revenuePerShareTTM,
            'Net income per share': companyOverview?.netIncomePerShareTTM,
            'Operating cash flow per share': companyOverview?.operatingCashFlowPerShareTTM,
            'Free cash flow per share': companyOverview?.freeCashFlowPerShareTTM,
            'Cash per share': companyOverview?.cashPerShareTTM,
            'Book value per share': companyOverview?.bookValuePerShareTTM,
            'Tangible book value per share': companyOverview?.tangibleBookValuePerShareTTM,
            'Shareholders equity per share': companyOverview?.shareholdersEquityPerShareTTM,
            'Interest debt per share': companyOverview?.interestDebtPerShareTTM,
          }}
          isLoading={isLoadingCompanyOverview}
        />
      </Paper>
    </Box>
  );
}
