/* eslint-disable global-require */
import { Box, Grid } from '@mui/material';
import './Badges.css';
import DividendBadge from '../../../assets/badges/dividend.png';
import GrowthBadge from '../../../assets/badges/growth.png';
import Badge from './Badge';

interface BadgesPropsModel {
  showDividendBadge: boolean,
  showGrowthBadge: boolean,
}

export default function Badges({
  showDividendBadge, showGrowthBadge,
}: BadgesPropsModel) {
  return (
    <Grid container style={{ width: '100%', height: '100%', padding: '4% 4% 4% 4%'}}>
      {showDividendBadge && (<Grid item xs={12} sm={6}>

        <Badge
          badgeSrc={DividendBadge}
          label="Dividend badge"
          description="DIVIDEND BADGE: annual dividend increases for at least 3 years"
        />
      </Grid>
      )}
      {showGrowthBadge && (<Grid item xs={12} sm={6}>

        <Badge
          badgeSrc={GrowthBadge}
          label="Growth badge"
          description="GROWTH BADGE: revenue per share increases for at least 3 years"
        />
      </Grid>
      )}
    </Grid>
    )
}
