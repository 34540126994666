import { Box, Button, Divider, Link, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import MultilineCustomCell from "../../../components/analyticsGrid/MultilineCustomCell";
import { loginRequest } from "../../../authConfig";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useAccount, useMsal } from "@azure/msal-react";
import ChooseBillingPeriodDialog from "../../../layout/components/ChooseBillingPeriodDialog";
import { useState } from "react";
import { SubscriptionPlanType } from "../../../models/subscriptionConfig";

export default function ComparePlans() {
    const { instance, accounts } = useMsal();
    const [isChooseBillingPeriodModalOpen, setIsChooseBillingPeriodModalOpen] = useState<boolean>(false);
    const [subscriptionPlan, setSubscriptionPlan] = useState<SubscriptionPlanType>(SubscriptionPlanType.PREMIUM);

    const rows: any[] = [
        {
            id: 1,
            feature: 'Advanced StockTradeIQ Stock screener',
            registration: '✓',
            premium: '✓',
            diamond: '✓',
            tooltip: 'Stock screener with dedicated features for value investing and dividend investing strategies.'
        },
        {
            id: 2,
            feature: 'Company details view',
            registration: '✓',
            premium: '✓',
            diamond: '✓',
            tooltip: 'Dedicated page for a stock with all the information you can find.'
        },
        {
            id: 3,
            feature: 'Dividend features: Q-shift, investment to goal, years of dividend increase',
            registration: '✓',
            premium: '✓',
            diamond: '✓',
            tooltip: 'A suite of dedicated features allowing one to find companies paying dividends in a specific month of the quarter, the value needed to be invested in order to achieve passive income goal, and a column allowing one to find by the number of years of dividend increase (exactly as we count for dividend aristocrats).'
        },
        {
            id: 5,
            feature: 'Customizable screener layout',
            registration: '✓',
            premium: '✓',
            diamond: '✓',
            tooltip: 'The layout is customizable. You can reorder columns, add them, or hide and save settings as screens for future use.'
        },
        {
            id: 6,
            feature: 'Multiple sort criterias',
            registration: '✓',
            premium: '✓',
            diamond: '✓',
            tooltip: 'You can sort by multiple criteria: simply sort by the first criteria on the grid, and then use CTRL + sort by another column (you will see numbers above columns displaying the order of sorting applied).'
        },
        {
            id: 7,
            feature: 'Similar companies hints',
            registration: '✓',
            premium: '✓',
            diamond: '✓',
            tooltip: 'After finding a specific company you can check our list of similar companies on the company page.'
        },
        {
            id: 8,
            feature: 'Key takeaways & STI scoring',
            registration: '< 15 views monthly',
            premium: 'Unlimited views monthly',
            diamond: 'Unlimited views monthly',
            tooltip: "Our original scoring mechanism allows users to learn as quickly as possible about the most important discoveries we've detected for a stock."
        },
        {
            id: 9,
            feature: 'Screener international comparison',
            registration: 'Single country at once',
            premium: 'Multiple countries + entire world',
            diamond: 'Multiple countries + entire world',
            tooltip: 'With our international comparison, you can compare opportunities from different countries. Apply multiple countries for a screener and sort by the same criteria.'
        },
        {
            id: 10,
            feature: 'Screener saveable settings and views',
            registration: '50 saved configurations',
            premium: '250 saved configurations',
            diamond: '500 saved configurations',
            tooltip: 'You can save applied filter criteria in multiple different views for later reuse.'
        },
        {
            id: 11,
            feature: 'Stock screener & portfolio integration',
            registration: '✓',
            premium: '✓',
            diamond: '✓',
            tooltip: 'Our two tools for stock screener and portfolio management integrate between each other. You can see on each stock page what is your position and you can use your position on the screener grid.'
        },
        {
            id: 12,
            feature: 'Fundamental results chart',
            registration: '✓',
            premium: '✓',
            diamond: '✓',
            tooltip: 'On the company page, there is a configurable dedicated chart component for preferable fundamentals.'
        },
        {
            id: 13,
            feature: 'Screener companies',
            registration: '10 per screen',
            premium: 'Unlimited',
            diamond: 'Unlimited',
            tooltip: 'How many companies do you want to see on a single screen of our screener grid?'
        },
        {
            id: 14,
            feature: 'Company\'s statistics',
            registration: '5 years of history',
            premium: '8 years of history',
            diamond: 'Unlimited',
            tooltip: "Below the fundamental chart, you can find the company's statistics for a selected plan's history."
        },
        {
            id: 20,
            feature: 'Portfolios',
            registration: '50 portfolio, 50 tickers',
            premium: '100 watchlists, 500 tickers',
            diamond: '200 watchlists, 800 tickers',
            tooltip: 'With our portfolio management, you can manage multiple portfolios and check how positions work for you, including historical dividends paid to you.'
        },
        {
            id: 15,
            feature: 'Watchlists',
            registration: '1 watchlist, 5 tickers',
            premium: '3 watchlists, 25 tickers',
            diamond: 'Unlimited watchlists, unlimited tickers',
            tooltip: 'Before you buy you probably will watch multiple opportunities. Make it simple with our watchlist features.'
        },
        {
            id: 16,
            feature: 'Community features',
            registration: '✓',
            premium: '✓',
            diamond: '✓',
            tooltip: 'Do you want to share your screener view and filter configuration? Use the share feature and deliver a link to your community. On a shared screen you can check popular are your links.'
        },
        {
            id: 17,
            feature: 'Price alerts',
            registration: '✕',
            premium: '5 price alerts',
            diamond: '30 price alerts',
            tooltip: 'Price alerts allow you to check for the right moment before you take action.'
        },
        {
            id: 18,
            feature: 'Fundamental alerts',
            registration: '✕',
            premium: '<5 fundamental alerts',
            diamond: '<30 fundamental alerts',
            tooltip: 'Fundamental alerts come with our dedication to fundamental investment style. Now you can wait for a company to reach e.g. expected level of P/E indicator or acceptable Debt to equity ratio.'
        },
        {
            id: 19,
            feature: 'Exclusive inner circle',
            registration: '✕',
            premium: 'Personal support',
            diamond: 'Personal support, design team personal contact',
            tooltip: 'Do you want to be in touch with us and talk directly about our data and features? Join the inner circle.'
        }
    ];

    const columns: GridColDef[] = [
        {
            field: 'feature',
            headerName: 'Feature',
            type: 'string',
            headerAlign: 'center',
            editable: false,
            sortable: false,
            flex: 2,
            renderCell: (params: any) => (
                <Tooltip title={params.row.tooltip} placement="top">
                    <span style={{ display: 'block', width: '100%' }}>
                        <MultilineCustomCell value={params.value} align='left' />
                    </span>
                </Tooltip>
            ),
            renderHeader: (params: any) => (
                <Box>
                    <Typography sx={{ fontSize: '20px', fontWeight: '600', textAlign: 'center' }}>
                        FEATURE
                    </Typography>
                </Box>
            )
        },
        {
            field: 'registration',
            headerName: 'Registration',
            type: 'string',
            headerAlign: 'center',
            editable: false,
            sortable: false,
            flex: 1,
            renderCell: (params: any) => (
                <Tooltip title={params.row.tooltip} placement="top">
                    <span style={{ display: 'block', width: '100%' }}>
                        <MultilineCustomCell value={params.value} align='center' />
                    </span>
                </Tooltip>
            ),
            renderHeader: (params: any) => (
                <Box>
                    <Typography sx={{ fontSize: '20px', fontWeight: '600', textAlign: 'center' }}>
                        REGISTRATION
                    </Typography>
                    <UnauthenticatedTemplate>
                        <Typography
                            onClick={() => instance.loginRedirect(loginRequest)}
                            sx={{
                                fontSize: '16px',
                                fontWeight: '400',
                                color: '#0094FF',
                                textAlign: 'center',
                                cursor: 'pointer',
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }}
                        >
                            Use for free
                        </Typography>
                    </UnauthenticatedTemplate>
                </Box>
            )
        },
        {
            field: 'premium',
            headerName: 'Premium',
            type: 'string',
            headerAlign: 'center',
            editable: false,
            sortable: false,
            cellClassName: 'premium-cell',
            flex: 1,
            renderCell: (params: any) => (
                <Tooltip title={params.row.tooltip} placement="top">
                    <span style={{ display: 'block', width: '100%' }}>
                        <MultilineCustomCell value={params.value} align='center' />
                    </span>
                </Tooltip>
            ),
            renderHeader: (params: any) => (
                <Box>
                    <Typography sx={{ fontSize: '20px', fontWeight: '600', textAlign: 'center' }}>
                        PREMIUM
                    </Typography>
                    <AuthenticatedTemplate>
                        <Typography sx={{
                            fontSize: '16px',
                            fontWeight: '400',
                            color: '#0094FF',
                            textAlign: 'center',
                            cursor: 'pointer',
                            '&:hover': {
                                textDecoration: 'underline'
                            }
                        }} onClick={() => { setSubscriptionPlan(SubscriptionPlanType.PREMIUM); setIsChooseBillingPeriodModalOpen(true); }} >
                            Start with free trial
                        </Typography>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <Typography
                            onClick={() => instance.loginRedirect(loginRequest)}
                            sx={{
                                fontSize: '16px',
                                fontWeight: '400',
                                color: '#0094FF',
                                textAlign: 'center',
                                cursor: 'pointer',
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }}
                        >
                            Register for free
                        </Typography>
                    </UnauthenticatedTemplate>
                </Box>
            )
        },
        {
            field: 'diamond',
            headerName: 'Diamond',
            type: 'string',
            headerAlign: 'center',
            editable: false,
            sortable: false,
            cellClassName: 'diamond-cell',
            flex: 1,
            renderCell: (params: any) => (
                <Tooltip title={params.row.tooltip} placement="top">
                    <span style={{ display: 'block', width: '100%' }}>
                        <MultilineCustomCell value={params.value} align='center' />
                    </span>
                </Tooltip>
            ),
            renderHeader: (params: any) => (
                <Box>
                    <Typography sx={{ fontSize: '20px', fontWeight: '600', textAlign: 'center' }}>
                        DIAMOND
                    </Typography>
                    <AuthenticatedTemplate>
                        <Typography sx={{
                            fontSize: '16px',
                            fontWeight: '400',
                            color: '#0094FF',
                            textAlign: 'center',
                            cursor: 'pointer',
                            '&:hover': {
                                textDecoration: 'underline'
                            }
                        }} onClick={() => { setSubscriptionPlan(SubscriptionPlanType.DIAMOND); setIsChooseBillingPeriodModalOpen(true); }}>
                            Start with free trial
                        </Typography>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <Typography
                            onClick={() => instance.loginRedirect(loginRequest)}
                            sx={{
                                fontSize: '16px',
                                fontWeight: '400',
                                color: '#0094FF',
                                textAlign: 'center',
                                cursor: 'pointer',
                                '&:hover': {
                                    textDecoration: 'underline'
                                }
                            }}
                        >
                            Register for free
                        </Typography>
                    </UnauthenticatedTemplate>
                </Box>
            )
        }
    ];
    return (
        <Box sx={{ background: 'white', paddingBottom: '50px', paddingTop: '20px' }}>
            <Paper sx={{ width: '94%', marginLeft: '3%', marginTop: '3%' }} elevation={4}>
                <Box
                    sx={{
                        minHeight: '1650px',
                        background: 'white',
                        padding: '0 60px 0 60px',
                        marginBottom: '50px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '30px'
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '44px',
                            fontWeight: '600',
                            textAlign: 'center',
                            marginBottom: '30px',
                            marginTop: '30px'
                        }}
                    >
                        Compare plans
                    </Typography>

                    <Box sx={{
                        width: '100%',
                        height: 'auto',
                        flex: '1 1 auto'
                    }}>

                        <DataGridPro
                            rows={rows}
                            columns={columns}
                            disableColumnMenu={true}
                            disableColumnFilter={true}
                            disableColumnSelector={true}
                            disableDensitySelector={true}
                            disableRowSelectionOnClick={true}
                            rowSelection={false}
                            disableColumnReorder={true}
                            disableColumnResize={true}
                            hideFooter={true}
                            isRowSelectable={() => false}
                            getRowHeight={() => 70}
                            autoHeight={true}
                            sx={{
                                height: 'auto !important',
                                maxHeight: 'none !important',
                                border: 'none !important',
                                '& .MuiDataGrid-main': {
                                    border: 'none',
                                    overflow: 'visible',
                                    maxHeight: 'none !important'
                                },
                                '& .MuiDataGrid-virtualScroller': {
                                    height: 'auto !important',
                                    maxHeight: 'none !important',
                                    overflow: 'visible !important'
                                },
                                '& .MuiDataGrid-virtualScrollerContent': {
                                    height: 'auto !important',
                                    maxHeight: 'none !important'
                                },
                                '& .MuiDataGrid-virtualScrollerRenderZone': {
                                    height: 'auto !important',
                                    maxHeight: 'none !important'
                                },
                                // Remove hover effects from rows and cells
                                '& .MuiDataGrid-row:hover': {
                                    backgroundColor: 'transparent !important',
                                },
                                '& .MuiDataGrid-cell:hover': {
                                    backgroundColor: 'transparent !important',
                                },
                                '& .MuiDataGrid-cell:focus': {
                                    outline: 'none !important',
                                },
                                '& .MuiDataGrid-cell:focus-within': {
                                    outline: 'none !important',
                                },
                                '& .MuiDataGrid-cell.Mui-selected': {
                                    backgroundColor: 'transparent !important',
                                },
                                '& .MuiDataGrid-row': {
                                    cursor: 'default',
                                    borderBottom: '12px solid transparent'
                                },
                                '& .MuiDataGrid-cell': {
                                    cursor: 'default',
                                },
                                '& .MuiDataGrid-columnHeader:hover': {
                                    backgroundColor: 'transparent !important',
                                },
                                '& .MuiDataGrid-columnHeader:focus': {
                                    outline: 'none !important',
                                },
                                '& .MuiDataGrid-columnHeader:focus-within': {
                                    outline: 'none !important',
                                },
                                '& .MuiDataGrid-columnHeader': {
                                    cursor: 'default !important',
                                },
                                '& .MuiDataGrid-columnSeparator': {
                                    visibility: 'hidden !important',
                                },
                                '& .MuiDataGrid-sortIcon': {
                                    display: 'none !important',
                                },
                                '& .MuiDataGrid-menuIcon': {
                                    display: 'none !important',
                                }
                            }}
                        />
                    </Box>

                    <AuthenticatedTemplate>
                        <Stack
                            direction='row'
                            spacing={10}
                            sx={{
                                width: '100%',
                                justifyContent: 'center',
                                mt: 4
                            }}
                        >
                            <Box sx={{ width: '55%' }} />
                            <Button
                                variant="outlined"
                                color="secondary"
                                sx={{
                                    width: '15%', fontSize: '14px', borderColor: '#0288D180', color: '#0288D180', height: '40px', fontWeight: '600'
                                }}
                                onClick={() => { setSubscriptionPlan(SubscriptionPlanType.PREMIUM); setIsChooseBillingPeriodModalOpen(true); }}
                            >
                                I choose
                                {' '}
                                PREMIUM
                            </Button>
                            <Box sx={{ width: '15%', height: '100%' }}>
                                <Button variant="contained"
                                    color="success"
                                    sx={{ width: '100%', height: '40px', fontSize: '14px', backgroundColor: '#7E57C2', fontWeight: '600' }}
                                    onClick={() => { setSubscriptionPlan(SubscriptionPlanType.DIAMOND); setIsChooseBillingPeriodModalOpen(true); }}>
                                    I choose
                                    {' '}
                                    DIAMOND
                                </Button>
                                <Stack
                                    direction="row"
                                    sx={{
                                        position: 'relative',
                                        width: '100%',
                                        textAlign: 'center',
                                        paddingTop: '5px'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '50%',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>
                                            Free&nbsp;trial
                                        </Typography>
                                    </Box>

                                    <Divider
                                        orientation="vertical"
                                        flexItem
                                    />

                                    <Box
                                        sx={{
                                            width: '50%',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>
                                            Most&nbsp;popular
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Box>
                        </Stack>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <Box
                            sx={{
                                width: '100%',
                                background: 'white',
                                display: 'flex',
                                flexDirection: 'column', // Stack button and typography row vertically
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                style={{
                                    padding: '12px 50px',
                                    margin: '10px',
                                    textTransform: 'none',
                                    borderRadius: '10px',  // Rounded edges
                                    background: '#30CFD0',  // Gradient background
                                    color: 'white',
                                    fontSize: '20px',
                                    boxShadow: '2px 8px 12.4px 1px #00000021'
                                }}
                                variant="contained"
                                size="large"
                                onClick={() => instance.loginRedirect(loginRequest)}
                                aria-label="I'm starting for free button"
                            >
                                I'm starting for free
                            </Button>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '16px', // Space between typographies
                                    marginTop: '8px'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '600'
                                    }}
                                >
                                    No card needed
                                </Typography>
                            </Box>
                        </Box>
                    </UnauthenticatedTemplate>
                </Box>
            </Paper>
            <ChooseBillingPeriodDialog subscriptionPlanType={subscriptionPlan} isDialogOpened={isChooseBillingPeriodModalOpen} setIsDialogOpened={setIsChooseBillingPeriodModalOpen} />
        </Box>
    )
}
