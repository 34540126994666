export enum SubscriptionPlanType {
    PREMIUM = 'premium',
    DIAMOND = 'diamond',
  }
  
  export interface SubscriptionPlan {
    name: string;
    displayName: string;
    resources: string[];
    pricing: {
      monthly: {
        regular: {
          current: number;
          old: number;
        };
        trial: {
          current: number;
          old: number;
        };
      };
      yearly: {
        regular: {
          current: number;
          old: number;
        };
        trial: {
          current: number;
          old: number;
        };
      };
    };
    isPremium?: boolean;
  }
  
  export const subscriptionPlans: Record<SubscriptionPlanType, SubscriptionPlan> = {
    [SubscriptionPlanType.PREMIUM]: {
      name: 'Premium',
      displayName: 'Premium',
      resources: [
        'Company’s statistics: 8 years',
        '3 watchlists, 25 tickers',
        '5 Fundamental alerts',
        '5 Price alerts per month',
        'Screener saveable settings configuration: 250',
        'Portfolio management: 100 portfolios'
      ],
      pricing: {
        monthly: {
          regular: {
            current: 7.99,
            old: 14.99,
          },
          trial: {
            current: 7.99,
            old: 14.99,
          },
        },
        yearly: {
          regular: {
            current: 79.99,
            old: 99.99,
          },
          trial: {
            current: 79.99,
            old: 99.99,
          },
        },
      },
    },
    [SubscriptionPlanType.DIAMOND]: {
      name: 'Diamond',
      displayName: 'Diamond',
      resources: [
        'Company’s statistics: UNLIMITED',
        'UNLIMITED  watchlists and tickers',
        '30 Fundamental',
        '30 Price alerts',
        'Screener saveable settings configuration: 500',
        'Portfolio management: 200 portfolios'
      ],
      pricing: {
        monthly: {
          regular: {
            current: 9.99,
            old: 19.99,
          },
          trial: {
            current: 9.99,
            old: 19.99,
          },
        },
        yearly: {
          regular: {
            current: 99.99,
            old: 119.99,
          },
          trial: {
            current: 99.99,
            old: 119.99,
          },
        },
      },
      isPremium: true,
    },
  };
  
  export const getSubscriptionProductId = (
    planType: SubscriptionPlanType,
    isAnnual: boolean,
    isTrial: boolean
  ): string => {
    const interval = isAnnual ? 'yearly' : 'monthly';
    const trialSuffix = isTrial ? '-trial' : '';
    
    return `${planType}-${interval}${trialSuffix}`;
  };
  
  export const getSubscriptionPrice = (
    planType: SubscriptionPlanType,
    isAnnual: boolean,
    isTrial: boolean
  ): number => {
    const plan = subscriptionPlans[planType];
    const interval = isAnnual ? 'yearly' : 'monthly';
    const priceType = isTrial ? 'trial' : 'regular';
    
    return plan.pricing[interval][priceType].current;
  };
  
  export const getSubscriptionOldPrice = (
    planType: SubscriptionPlanType,
    isAnnual: boolean,
    isTrial: boolean
  ): number => {
    const plan = subscriptionPlans[planType];
    const interval = isAnnual ? 'yearly' : 'monthly';
    const priceType = isTrial ? 'trial' : 'regular';
    
    return plan.pricing[interval][priceType].old;
  };