import {
  Box, Button, FormControl, TextField, Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import SupportFormWrapper from '../../../components/SupportForm';
import SupportService from '../../../services/supportService';
import useGlobalStyles from '../../../styles/useGlobalStyles';

export default function BugReport() {
  const postFeature = useMutation((feature: any) => SupportService.postBugReport(feature));

  const { enqueueSnackbar } = useSnackbar();
  const globalClasses = useGlobalStyles();

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: Yup.object({
      message: Yup.string()
        .min(20, 'Must be at least 20 characters')
        .max(500, 'Must be 500 characters or less')
        .required('Required'),
    }),
    onSubmit: (values) => {
      enqueueSnackbar('Bug report has been sent. Thank you for you effort to improve the STI platform');
      postFeature.mutate(JSON.stringify(values, null, 2));
    },
  });

  return (
    <Box sx={{ minHeight: '100%', width: '100%' }}>
      <Helmet>
        <title>Report a Bug | Help Improve StockTradeIQ</title>
      </Helmet>
      <Box sx={{ height: '100%', width: '100%' }} className="center-items">
        <Box className="support-form-container">
          <SupportFormWrapper
            header="Bug report"
            subheader="Help us improve the quality of our app by reporting any bugs or technical issues you encounter using form below. "
          >
            <Box sx={{ width: '100%' }} className={globalClasses.header}>
              <Typography variant="h4" sx={{ padding: '10px 0px 10px 0px' }} />
            </Box>
            <form onSubmit={formik.handleSubmit}>
              <FormControl sx={{ width: '90%' }}>
                <TextField
                  id="message"
                  name="message"
                  label="Message"
                  multiline
                  margin="normal"
                  fullWidth
                  minRows={5}
                  maxRows={8}
                  variant="filled"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  error={formik.touched.message && Boolean(formik.errors.message)}
                  helperText={formik.touched.message && formik.errors.message}
                />
              </FormControl>
              <Box sx={{ width: '100%' }} className="center-items">
                <Button variant="contained" sx={{ margin: '10% 5px 5px 5px' }} size="large" type="submit">
                  Send
                </Button>
              </Box>
            </form>
          </SupportFormWrapper>
        </Box>
      </Box>
    </Box>
  );
}
