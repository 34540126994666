import { Box, TableCell, Typography } from "@mui/material";
import Humanize from "../../services/humanize";

export default function MultilineCustomCell(params: any) {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                ...(params.align && { justifyContent: params.align === 'center' ? 'center' : 'flex-start' }),
                background: "rgba(240, 240, 240, 0.4)",
                width: '100%',
                height: '80%',
                paddingLeft: params.align === 'center' ? '0px' : '10px',
                paddingRight: params.align === 'center' ? '0px' : '40px',
                borderRadius: '8px',
                margingTop: '10px',
                marginBottom: '10px'
            }}
        >
            <TableCell
                {...params}
                sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    ...(params.align && { justifyContent: params.align === 'center' ? 'center' : 'flex-start' }),
                    width: '100%',
                }}
            >
                <Typography
                    sx={{
                        whiteSpace: 'pre-wrap',  // Preserves line breaks and wraps text
                        wordBreak: 'break-word', // Breaks long words if necessary
                        overflowWrap: 'break-word',
                        width: '100%',
                        padding: '8px'
                    }}
                >
                    {params.value}
                </Typography>
            </TableCell>
        </Box>
    );
};