import { createTheme } from '@mui/material/styles';
import type { } from '@mui/x-data-grid-pro/themeAugmentation';
import globalDarkTheme from './globalDarkTheme';
import globalLightTheme from './globalLightTheme';
import type { } from '@mui/x-date-pickers/themeAugmentation';
import type { } from '@mui/x-date-pickers-pro/themeAugmentation';

declare global {
  namespace React {
    interface DOMAttributes<T> {
      onResize?: ReactEventHandler<T> | undefined;
      onResizeCapture?: ReactEventHandler<T> | undefined;
      nonce?: string | undefined;
    }
  }
}

export default function globalTheme(isLight: boolean) {
  let theme = isLight ? globalLightTheme : globalDarkTheme;
  theme = createTheme(
    {
      typography: {
        fontFamily: [
          'Calibre',
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
        ].join(','),
        // Optionally customize specific variants
        h1: {
          fontFamily: 'Calibre',
          fontWeight: 600,
        },
        h2: {
          fontFamily: 'Calibre',
          fontWeight: 600,
        },
        h3: {
          fontFamily: 'Calibre',
          fontWeight: 500,
        },
        h4: {
          fontFamily: 'Calibre',
          fontWeight: 500,
        },
        h5: {
          fontFamily: 'Calibre',
          fontWeight: 500,
        },
        h6: {
          fontFamily: 'Calibre',
          fontWeight: 500,
        },
        body1: {
          fontFamily: 'Calibre',
          fontWeight: 400,
        },
        body2: {
          fontFamily: 'Calibre',
          fontWeight: 400,
        },
        button: {
          fontFamily: 'Calibre',
          fontWeight: 500,
          textTransform: 'none', // Optional: prevents automatic uppercase transform
        },
      },
      components: {
        MuiDataGrid: {
          styleOverrides: {
            root: {
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
              },
              '& .MuiDataGrid-columnHeaders': {
                marginBottom: '10px', // Adjust the margin as needed
              },
              '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-cell': {
                borderBottom: 'none', // Remove bottom borders of headers and cells
              },
              '& .actions-column-header': {
                marginRight: '10px',
              },
              '& .MuiDataGrid-pinnedColumns': {
                boxShadow: 'none !important',
              },
              '& .MuiDataGrid-pinnedColumnHeaders': {
                boxShadow: 'none !important',
              },
              '& .actions-column': {
                backgroundColor: '#F4FCFF', // Set your desired color here
              },
              '& .diamond-cell, & .diamond-cell:hover, & .MuiDataGrid-row:hover .diamond-cell': {
                backgroundColor: '#76F7FF52 !important', // Set your desired color here
              },
              '& .premium-cell, & .premium-cell:hover, & .MuiDataGrid-row:hover .premium-cell': {
                backgroundColor: '#BEFBFF52 !important'
              },
              '& .MuiDataGrid-cell': {
                borderRight: 'none', // Remove the right border of cells
              },
              '& .MuiDataGrid-row': {
                borderBottom: 'none', // Remove the border between rows
              },
            },
          },
        },
        MuiTableCell: {  // Add this section
          styleOverrides: {
            root: {
              borderBottom: 'none',
            }
          }
        },
        MuiButton: {
          styleOverrides: {
            root: {
              color: theme.palette.text.primary,
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
            },
            contained: {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
            },
          },
        },
        MuiPaper: {
          defaultProps: {
            elevation: 2
          },
          styleOverrides: {
            root: {
              overflow: 'hidden',
            },
          },
        },
        MuiCssBaseline: {
          styleOverrides: {
            'html, body': {
              '&::-webkit-scrollbar': {
                width: 0,
                display: 'none',
              },
              overflow: '-moz-scrollbars-none',
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
              WebkitScrollbarWidth: 'none',
              '&::-o-scrollbar': {
                display: 'none',
              },
            },
          },
        },
      },
    },
    theme,
  );
  return theme;
}
