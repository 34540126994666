import { Box, Typography } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { createRef, useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import ChartServise from '../../services/chartsService';
import Loader from '../Loader';

interface CartesianChartPropsModel{
  data: any,
  isLoading: boolean,
  series: any,
  inverse: boolean,
  yValueSufix: string,
  showLegend?: boolean,
  dataZoom?: any,
  height?: number,
  width?: number
}

export default function CartesianChart(props: CartesianChartPropsModel) {
  const {
    data, isLoading, series, inverse, yValueSufix, showLegend = true, dataZoom, height, width
  } = props;
  const theme: Theme = useTheme();
  const [option, setOption] = useState<any>();
  const chartRef: any = createRef();

  useEffect(() => {
    if (data) {
      const customOption = {
        dataset: {
          source: data,
        },
        title: {
          show: false,
        },
        legend: {
          type: 'scroll',
          show: showLegend,
          orient: 'horizontal',
          bottom: 'auto',
          top: '0px',
        },
        xAxis: {
          type: 'category',
          inverse,
        },
        grid: {
          left: '80px',
          right: '8%',
          top: showLegend ? '15%' : '5%',
          bottom: 30,
        },
        dataZoom,
        series,
      };
      const newOption = ChartServise.getLineChartOption(theme, customOption, yValueSufix);
      setOption(() => (newOption));
    }
  }, [data, theme, isLoading, dataZoom, series]);

  return (
    <Box
      sx={{
        minWidth: '350px', flexGrow: 1, position: 'relative', height: '100%',
      }}
      style={{ height: '100%' }}
      className="center-items"
    >
      { option && data?.length === 0 && !isLoading
      && <Typography> No data to display </Typography>}
      {(!option || isLoading)
            && <Loader/>}
      {option && !isLoading && data?.length !== 0
                && (
                <ReactECharts
                  notMerge
                  ref={chartRef}
                  option={option}
                  opts={{ height: height, width: width}}
                  style={{
                    height: '100%',
                    width: '100%',
                    minHeight: height
                  }}
                />
                )}
    </Box>
  );
}
