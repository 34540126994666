import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { PublicClientApplication } from '@azure/msal-browser';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StyledEngineProvider } from '@mui/material/styles';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { msalConfig } from './authConfig';
import TagManager from '@sooro-io/react-gtm-module'

const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();

export default msalInstance;
const queryClient = new QueryClient();

const tagManagerArgs = {
  gtmId: 'GTM-PDZ2RG6X',
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <App msalInstance={msalInstance} />
      </StyledEngineProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
