/* eslint-disable no-restricted-syntax */
import { useQuery, useQueryClient } from '@tanstack/react-query';
import utils from '../../../common/utils';
import StockDetailedData from '../../../models/stockDetailedData';
import StockService from '../../../services/stockService';
import { useFreeResourcesExceededDialogContext } from '../../../contexts/FreeResourcesExceededDialogContext';

export default function useGetStockDetailedData(
  exchanges: string[],
  indexes: string[],
  onlyFavourite: boolean,
  onlyIgnored: boolean,
) {
  const queryClient = useQueryClient();
  const freeResourcesExceededDialogContext = useFreeResourcesExceededDialogContext();

  return useQuery(
    [
      'getStockDetailedData',
      onlyIgnored ? 'Ignored' : [exchanges, indexes],
    ],
    () => StockService.getStocksDetailedData(
      exchanges,
      indexes,
      onlyFavourite,
      onlyIgnored,
      queryClient,
    ),
    {
      ...(!onlyIgnored && !onlyFavourite ? { cacheTime: 0 } : {}),
      retry: (failureCount, error: any) => {
        // Don't retry on 403 errors
        if (error?.response?.status === 403) {
          return false;
        }
        // Default retry behavior for other errors (3 times)
        return failureCount < 3;
      },
      onSuccess: (data: StockDetailedData[] | undefined) => {
        if (onlyFavourite || onlyIgnored) {
          return;
        }
        if (data) {
          const newExchanges = {} as any;
          const newIndexes = {} as any;
          for (const elem of data) {
            if (newExchanges[elem.exchange]) {
              newExchanges[elem.exchange] = [elem, ...newExchanges[elem.exchange]];
            } else {
              newExchanges[elem.exchange] = [elem];
            }
            for (const index of elem.indexes) {
              if (newIndexes[index]) {
                newIndexes[index].push(elem);
              } else {
                newIndexes[index] = [elem];
              }
            }
          }
          for (const [exchange, value] of Object.entries(newExchanges)) {
            if (exchanges.includes(exchange)) {
              queryClient.setQueryData(
                ['exchange', exchange],
                (oldValue: any) => {
                  if (oldValue && oldValue.length > (value as []).length) {
                    return oldValue;
                  }
                  return value;
                },
              );
            }
          }
          for (const [index, value] of Object.entries(newIndexes)) {
            if (indexes.includes(index)) {
              queryClient.setQueryData(
                ['index', index],
                (oldValue: any) => {
                  if (oldValue && oldValue.length > (value as []).length) {
                    return oldValue;
                  }
                  return value;
                },
              );
            }
          }
          let newStockDetailedData = [] as StockDetailedData[];
          for (const exchange of exchanges) {
            const elemsToAdd = queryClient.getQueryData(['exchange', exchange]);
            if (elemsToAdd) {
              newStockDetailedData = [...(elemsToAdd as []), ...newStockDetailedData];
            } else {
              queryClient.setQueryData(['exchange', exchange], []);
            }
          }
          for (const index of indexes) {
            const elemsToAdd = queryClient.getQueryData(['index', index]);
            if (elemsToAdd) {
              newStockDetailedData = [...(elemsToAdd as []), ...newStockDetailedData];
            } else {
              queryClient.setQueryData(['index', index], []);
            }
          }

          queryClient.setQueryData(['getStockDetailedData', [exchanges, indexes]], utils.getUniqueListBy(newStockDetailedData, 'symbol'));
        }
      },
      onError: ((error: any) => {
        if (error?.response?.status == 403) {
          freeResourcesExceededDialogContext.setDialogText('COUNTRIES SEARCH');
          freeResourcesExceededDialogContext.setIsDialogOpened(true);
        }
      }),
    },
  );
}
