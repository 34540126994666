import { useCallback } from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import {
  SubscriptionPlanType,
  getSubscriptionProductId
} from '../models/subscriptionConfig';

interface FastSpringCheckoutConfig {
  products: Array<{ path: string }>;
  paymentContact?: { email: string };
  checkout: boolean;
}

interface FastSpringBuilder {
  push: (config: FastSpringCheckoutConfig) => void;
}

declare global {
  interface Window {
    fastspring: {
      builder: FastSpringBuilder;
    };
    fbq: (track: string, event: string, params?: object) => void;
  }
}

interface IdTokenClaims {
  email?: string;
  [key: string]: unknown;
}

export const useSubscriptionCheckout = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const openCheckout = useCallback((productId: string) => {
    const idTokenClaims = account?.idTokenClaims as IdTokenClaims | undefined;
    const email = idTokenClaims?.email;

    const checkoutConfig: FastSpringCheckoutConfig = {
      products: [
        {
          path: productId,
        },
      ],
      checkout: true,
    };

    if (typeof email === 'string' && email) {
      checkoutConfig.paymentContact = { email };
    }

    window.fastspring.builder.push(checkoutConfig);
  }, [account]);

  const buySubscription = useCallback((
    planType: SubscriptionPlanType,
    isAnnual: boolean,
    isTrial: boolean
  ) => {
    const productId = getSubscriptionProductId(planType, isAnnual, isTrial);

    if (typeof window.fbq !== 'undefined') {
      window.fbq('track', 'InitiateCheckout', {
        content_ids: [productId],
        content_type: 'product',
      });
    }

    openCheckout(productId);
  }, [openCheckout]);

  return {
    buySubscription,
    openCheckout,
  };
};